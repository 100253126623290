import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

export default class MenuLink extends PureComponent {
	static propTypes = {
		menuItem: PropTypes.object.isRequired
	};

	render() {
		const { menuItem } = this.props;

		if (menuItem.route === "external") {
			return (
				<a href={menuItem.url} rel="external noopener noreferrer" target="_blank">
					{menuItem.name}
				</a>
			);
		}

		return <NavLink to={menuItem.url}>{menuItem.name}</NavLink>;
	}
}
