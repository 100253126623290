import React, { PureComponent } from "react";
import Image, { imagePropTypes } from "./Image.js";

export default class NodeImage extends PureComponent {
	static propTypes = {
		image: imagePropTypes
	};

	render() {
		const { image } = this.props;
		if (!image) return null;
		return (
			<div className="NodeImage">
				<Image image={image} />
			</div>
		);
	}
}
