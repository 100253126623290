import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export default class GberInformationSheetsNodes extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		nodes: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				alias: PropTypes.string.isRequired,
				country: PropTypes.shape({
					code: PropTypes.string.isRequired,
					name: PropTypes.string.isRequired
				}).isRequired,
				year: PropTypes.number.isRequired,
				number: PropTypes.string.isRequired,
				gberAidReference: PropTypes.string.isRequired
			})
		).isRequired
	};
	render() {
		const { t, nodes } = this.props;
		return (
			<Fragment>
				{nodes.map((node, index) => (
					<li key={index}>
						<NavLink to={node.alias}>{node.title}</NavLink>
						<div>
							<span>
								{t("GberInformationSheet.country")}: {node.country.name}
							</span>
							<span>
								{t("GberInformationSheet.year")}: {node.year}
							</span>
							<span>
								{t("GberInformationSheet.number")}: {node.number}
							</span>
							<span>
								{t("GberInformationSheet.gberAidReference")}: {node.gberAidReference}
							</span>
						</div>
					</li>
				))}
			</Fragment>
		);
	}
}
