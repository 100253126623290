import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";

export default class ImplementationStatusSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		years: PropTypes.array.isRequired,
        implStatusIcel: PropTypes.array,
        implStatusLiech: PropTypes.array,
        implStatusNor: PropTypes.array
	};

	render() {
		const { t,years,implStatusIcel,implStatusLiech,implStatusNor } = this.props;
		return (
			<Fragment>
				<div className="field-type-text search">
					<label htmlFor="field-name-q">{t("ListingPage.status_database_case.search")}</label>
					<input id="field-name-q" type="text" maxLength="64" name="q" />
				</div>
				<div className="field-type-text eu_ref">
					<label htmlFor="field-name-q">{t("ListingPage.status_database_case.eu_ref")}</label>
					<input id="field-name-q" type="text" maxLength="64" name="eu_ref" />
				</div>
				<div className="field-type-text celex_num">
					<label htmlFor="field-name-q">{t("ListingPage.status_database_case.celex_num")}</label>
					<input id="field-name-q" type="text" maxLength="64" name="celex_num" />
				</div>
                <div className="field-type-select">
					<label htmlFor="field-name-impl-status-nor">{t("ListingPage.status_database_case.implStatusNor")}</label>
					<select id="field-name-impl-status-nor" name="impl_status_nor">
						<option value="">{t("ListingPage.status_database_case._implStatusNor")}</option>
						{implStatusNor.map(implStatusNor => (
							<option key={implStatusNor} value={implStatusNor}>
								{implStatusNor}
							</option>
						))}
					</select>
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-impl-status-icel">{t("ListingPage.status_database_case.implStatusIcel")}</label>
					<select id="field-name-impl-status-icel" name="impl_status_icel">
						<option value="">{t("ListingPage.status_database_case._implStatusIcel")}</option>
						{implStatusIcel.map(implStatusIcel => (
							<option key={implStatusIcel} value={implStatusIcel}>
								{implStatusIcel}
							</option>
						))}
					</select>
				</div>
                <div className="field-type-select">
					<label htmlFor="field-name-impl-status-liech">{t("ListingPage.status_database_case.implStatusLiech")}</label>
					<select id="field-name-impl-status-liech" name="impl_status_liech">
						<option value="">{t("ListingPage.status_database_case._implStatusLiech")}</option>
						{implStatusLiech.map(implStatusLiech => (
							<option key={implStatusLiech} value={implStatusLiech}>
								{implStatusLiech}
							</option>
						))}
					</select>
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-year">{t("ListingPage.status_database_case.year")}</label>
					<select id="field-name-year" name="year">
						<option value="">{t("ListingPage.status_database_case._year")}</option>
						{years.map(year => (
							<option key={year}>{year}</option>
						))}
					</select>
				</div>
			</Fragment>
		);
	}
}
