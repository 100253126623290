import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { termPropTypes } from "common/Term.js";

export default class EventsSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		types: PropTypes.arrayOf(termPropTypes).isRequired
	};

	render() {
		const { t, types } = this.props;
		return (
			<Fragment>
				<div className="field-type-text search">
					<label htmlFor="field-name-q">{t("ListingPage.events.search")}</label>
					<input id="field-name-q" type="text" maxLength="64" name="q" />
				</div>
				<div className="field-type-date">
					<label htmlFor="field-name-from">{t("ListingPage.events.from")}</label>
					<input id="field-name-from" type="date" name="from" />
				</div>
				<div className="field-type-date">
					<label htmlFor="field-name-to">{t("ListingPage.events.to")}</label>
					<input id="field-name-to" type="date" name="to" />
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-type">{t("ListingPage.events.type")}</label>
					<select id="field-name-type" name="type">
						<option value="">{t("ListingPage.events._type")}</option>
						{types.map(term => (
							<option key={term.id} value={term.id}>
								{term.title}
							</option>
						))}
					</select>
				</div>
			</Fragment>
		);
	}
}
