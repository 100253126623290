import React, { Fragment, PureComponent } from "react";
import Tooltip from 'common//Tooltip';
import PropTypes from "prop-types";

export default class ImplementationStatus extends PureComponent {
	static propTypes = {
		t: PropTypes.func,
		nodes: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				eftasurvActType: PropTypes.string.isRequired,
				eftasurvEuReference: PropTypes.string,
				eftasurvComplianceDate: PropTypes.string,
				eftasurvCelexNumber: PropTypes.string,
				eftasurvImplStatusIceland: PropTypes.string,
				eftasurvImplStatusLiechtenstein: PropTypes.string,
				eftasurvImplStatusNorway: PropTypes.string,
				eftasurvNationalMeasuresOrigLang: PropTypes.string,
				eftasurvNationalMeasuresLie: PropTypes.string,
				eftasurvNationalMeasuresIce: PropTypes.string,
				eftasurvNationalMeasuresNor: PropTypes.string,
				eftasurvNationalMeasuresEeaStateLie: PropTypes.string,
				eftasurvNationalMeasuresEeaStateIce: PropTypes.string,
				eftasurvNationalMeasuresEeaStateNor: PropTypes.string,
				eftasurvActStatus: PropTypes.string
			})
		)
	};
	
render() {
		const { nodes } = this.props;
		// JS for thumbnail presentation only
	return (
		<Fragment>
			{nodes.map((node, index) => (
				<li key={index}>
					<p className="impl-status-title">{node.title}</p>
					<div className="impl-status-list">
						<span className="impl-status-list-item"><span>{'Type: '}</span><span>{node.eftasurvActType}</span></span>
						<span className="impl-status-list-item"><span>{'EU Ref.: '}</span><span>{node.eftasurvEuReference}</span></span>
						<span className="impl-status-list-item"><span>{'EEA Compliance date: '}</span><span>{node.eftasurvComplianceDate}</span></span>
						<span className="impl-status-list-item"><span>{'Celex no.: '}</span><span>{node.eftasurvCelexNumber}</span></span>
						<span className="impl-status-list-item"><span>{'Act.Status: '}</span><span>{node.eftasurvActStatus}</span></span>
					</div>
					<div className="impl-status-per-state">
					{node.eftasurvNationalMeasuresEeaStateNor === 'Norway' ?
							<Tooltip status={node.eftasurvImplStatusNorway.toLowerCase()} NationalMeasures={node.eftasurvNationalMeasuresNor} text="Implementation NOR" country={node.eftasurvNationalMeasuresEeaStateNor} />
							:
							<Tooltip text="Implementation NOR" country='' status={node.eftasurvImplStatusNorway.toLowerCase()} />
						}
						{node.eftasurvNationalMeasuresEeaStateIce === 'Iceland' ?
							<Tooltip status={node.eftasurvImplStatusIceland.toLowerCase()} NationalMeasures={node.eftasurvNationalMeasuresIce} text="Implementation ICE" country={node.eftasurvNationalMeasuresEeaStateIce} />
							:
							<Tooltip text="Implementation ICE" country='' status={node.eftasurvImplStatusIceland.toLowerCase()} />
						}
						{node.eftasurvNationalMeasuresEeaStateLie === 'Liechtenstein' ?
							<Tooltip status={node.eftasurvImplStatusLiechtenstein.toLowerCase()} NationalMeasures={node.eftasurvNationalMeasuresLie} text="Implementation LIE" country={node.eftasurvNationalMeasuresEeaStateLie} />
							:
							<Tooltip text="Implementation LIE" country='' status={node.eftasurvImplStatusLiechtenstein.toLowerCase()} />
						}
					</div>
				</li>
			))}
			</Fragment>
		);
	}
}
