import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class Wrapper extends PureComponent {
	static propTypes = {
		className: PropTypes.string.isRequired
	};

	static defaultProps = {
		className: "Wrapper"
	};

	render() {
		const { className, children } = this.props;
		// Only render wrapper if there are any actual react children wrapped.
		// React.Children.toArray ignores undefined elements.
		if (!React.Children.toArray(children).length) return null;
		return <div className={className}>{children}</div>;
	}
}
