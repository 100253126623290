import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Attachments, { attachmentsPropTypes } from "common/Attachments.js";
import RelatedContent, { relatedContentPropTypes } from "common/RelatedContent.js";
import RelatedDocuments, { relatedDocumentsPropTypes } from "common/RelatedDocuments.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import RelatedLinks, { relatedLinksPropTypes } from "common/RelatedLinks.js";
import NodeImage from "common/NodeImage.js";
import Languages from "common/Languages.js";
import SocialShare from "common/SocialShare.js";
import { renderUrl } from "common/utils.js";
import Wrapper from "common/Wrapper.js";
import { imagePropTypes } from "common/Image.js";

const CompetitionCase = ({ t, node }) => (
	<Fragment>
		<Languages languages={node.languages} language={node.language} alias={node.alias} />
		<h1>{node.title}</h1>
		<NodeImage image={node.image} />
		{node.body && <ClickableDrupalContent content={node.body} />}
		<Attachments t={t} attachments={node.attachments} />
		<RelatedDocuments t={t} relatedDocuments={node.relatedDocuments} />
		<RelatedLinks t={t} relatedLinks={node.relatedLinks} />
		<Wrapper className="NodeMetadataWrapper">
			{node.number && (
				<div className="metadata">
					<div className="label">{t("CompetitionCase.competitionCaseNumber")}</div>
					<div>{node.number}</div>
				</div>
			)}
		</Wrapper>
		<SocialShare t={t} url={renderUrl(node.alias, node.language)} />
		<RelatedContent t={t} relatedContent={node.relatedContent} />
	</Fragment>
);

const competitionCasePropTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	body: PropTypes.string,
	image: imagePropTypes,
	attachments: attachmentsPropTypes,
	relatedContent: relatedContentPropTypes,
	relatedDocuments: relatedDocumentsPropTypes,
	relatedLinks: relatedLinksPropTypes,
	number: PropTypes.string
};

CompetitionCase.propTypes = {
	t: PropTypes.func.isRequired,
	node: PropTypes.shape(competitionCasePropTypes)
};

export default React.memo(CompetitionCase);
