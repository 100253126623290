import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Router, Route } from "react-router-dom";
import "sass/main.scss";
import history from "./history.js";
import Loading from "common/Loading.js";
import DocumentTitle from "common/DocumentTitle.js";
import SecondLevelMenu from "common/SecondLevelMenu.js";
import DocumentMeta from "common/DocumentMeta.js";
import Error404 from "common/Error404.js";
import Error5xx from "common/Error5xx.js";
import ErrorNetwork from "common/ErrorNetwork.js";
import { scrollTo } from "common/utils.js";
import { GLOBAL_BLOCK_TITLES } from "config.js";
import { loadBlocksFromServer, loadMainMenuFromServer, loadNodeFromServer } from "./DataAccess.js";
import AppHeader from "./AppHeader.js";
import AppFooter from "./AppFooter.js";
import CompetitionCase from "pages/CompetitionCase.js";
import Decision from "pages/Decision.js";
import DraftTechnicalNotification from "pages/DraftTechnicalNotification.js";
import Event from "pages/Event.js";
import FoodSafetyMission from "pages/FoodSafetyMission.js";
import Form from "pages/Form.js";
import GberInformationSheet from "pages/GberInformationSheet.js";
import LandingPage from "pages/LandingPage.js";
import ListingPage from "pages/listing/ListingPage.js";
import Page from "pages/Page.js";
import PressRelease from "pages/PressRelease.js";
import Publication from "pages/Publication.js";
import Search from "pages/Search.js";
import Sitemap from "pages/Sitemap.js";
import StaffMember from "pages/StaffMember.js";
import Polyglot from "node-polyglot";
import i18n from "i18n.en.js";
import { trackPageView } from "config.js";
const polyglot = new Polyglot({ locale: "en", phrases: i18n });
export const t = polyglot.t.bind(polyglot);

export class RoutedApp extends PureComponent {
	render() {
		return (
			<Router history={history}>
				<Route
					path=":url(/.*)"
					render={props => (
						<App
							url={props.match.params.url}
							search={props.location.search ? props.location.search.replace(/^\?/, "") : undefined}
						/>
					)}
				/>
			</Router>
		);
	}
}

export default class App extends PureComponent {
	state = {
		blocks: undefined,
		menu: undefined,
		data: undefined,
		dataStatus: undefined // ['pending', 'success', 'error-404', 'error-5xx', 'error-network']
	};

	static propTypes = {
		url: PropTypes.string.isRequired,
		search: PropTypes.string
	};

	componentDidMount() {
		// ssr.php may have already provided the data we need.
		if (window.SERVER_DATA && window.SERVER_DATA.App) {
			this.setState(window.SERVER_DATA.App);
			delete window.SERVER_DATA.App;
			return;
		}
		this.loadStaticDataFromServerAndSetState();
	}
	retry = () => {
		this.loadDataFromServerAndSetState();
	};

	/**
	 * Loads static data from server such as blocks, menu etc. These never
	 * change during the front-end lifetime (unless a full page refresh is
	 * issued of course).
	 */
	loadStaticDataFromServerAndSetState() {
		// No need to handle failure since this code only runs on dev.
		loadBlocksFromServer(GLOBAL_BLOCK_TITLES).then(blocks => {
			this.setState({ blocks: blocks });
		});
		// No need to handle failure since this code only runs on dev.
		loadMainMenuFromServer().then(menu => {
			this.setState({ menu: menu });
		});
	}

	/**
	 * Loads data from the server which depends on the current URL. Assumes that
	 * loadStaticDataFromServerAndSetState() has been executed and fetched all
	 * its data.
	 */
	loadDataFromServerAndSetState() {
		const { url, search } = this.props;

		// ESA-210 Redirect trailing "/" to non "/" URL.
		// ".+" in regex makes sure to exclude frontpage which is "/".
		if (url.match(/.+\/$/)) {
			console.log("Trailing slash found in URL. Moving to non slash URL.");
			history.replace(url.substr(0, url.length - 1));
			return;
		}

		console.log('Setting { dataStatus: "pending" } so spinner appears.');
		this.setState({ dataStatus: "pending" });

		// Keep track of a global incremental request id so we can ignore out of
		// date (stale) responses.
		const requestId = (this.lastRequestId = (this.lastRequestId || 0) + 1);
		loadNodeFromServer(url, search)
			.then(result => {
				if (requestId !== this.lastRequestId) return; // Ignore stale response.
				console.log(`XHR loaded for url: ${url}${search ? `, search: ${search || ""}` : ``}`);
				this.setState({ data: { node: result }, dataStatus: "success" });
				trackPageView();
			})
			.catch(error => {
				console.log(`XHR errored for url: ${url}${search ? `, search: ${search || ""}` : ``}`);
				if (error.response && error.response.status === 404) {
					this.setState({ data: undefined, dataStatus: "error-404" });
				} else if (error.code === "ECONNABORTED" || !window.navigator.onLine) {
					this.setState({ data: undefined, dataStatus: "error-network" });
				} else {
					this.setState({ data: undefined, dataStatus: "error-5xx" });
					console.log(error);
				}
			})
			.finally(() => {
				scrollTo({ top: 0, behavior: "auto" });
			});
	}

	/**
	 * Determines whether core state has been loaded from the server.
	 */
	isCoreStateLoaded = state => state.blocks && state.menu;

	componentDidUpdate(prevProps, prevState) {
		// If core state has just loaded from server but data is not there, and
		// this is not a 404, then we need to load the data. This is only applicable
		// on dev mode since ssr.php fetches all the relevant data on window on
		// initial page load.
		if (
			!this.isCoreStateLoaded(prevState) &&
			this.isCoreStateLoaded(this.state) &&
			this.state.data === undefined &&
			this.state.dataStatus !== "error-404"
		) {
			this.loadDataFromServerAndSetState();
			return;
		}
		if (prevProps.url !== this.props.url || prevProps.search !== this.props.search) {
			console.log(
				"Page URL changed from " +
					prevProps.url +
					(prevProps.search ? "?" + prevProps.search : "") +
					" to " +
					this.props.url +
					(this.props.search ? "?" + this.props.search : "") +
					". Loading data from server"
			);
			this.loadDataFromServerAndSetState();
			return;
		}
		if (
			this.state.data &&
			prevState.data !== this.state.data &&
			window.location.hash &&
			window.location.hash.length > 1
		) {
			console.log("Rendered new content and URL contains hash. Refresh hash.");
			window.location = window.location.pathname + window.location.search + window.location.hash;
		}
	}

	render() {
		const { blocks, menu, data, dataStatus } = this.state;
		const { search } = this.props;

		// Do not render application until blocks and menu are loaded.
		// We need this so we can ensure the body of the application will do have
		// those to work with.
		// This part of the process is only applicable while development, because
		// ssr.php already provides these on initial page view in window.SERVER_DATA.
		if (blocks === undefined || menu === undefined) {
			return (
				<Fragment>
					<DocumentTitle title={[t("loading"), t("title")]} />
					<Loading t={t} />
				</Fragment>
			);
		}

		let content;
		if (dataStatus === "error-404") {
			content = <Error404 t={t} />;
		} else if (dataStatus === "error-5xx") {
			content = <Error5xx t={t} retry={this.retry} />;
		} else if (dataStatus === "error-network") {
			content = <ErrorNetwork t={t} retry={this.retry} />;
		} else if ((data === undefined && dataStatus === "pending") || dataStatus === undefined) {
			content = (
				<Fragment>
					<DocumentTitle title={[t("loading"), t("title")]} />
					<Loading t={t} />
				</Fragment>
			);
		} else if (data !== undefined && data.node !== undefined) {
			content = (
				<Fragment>
					<DocumentTitle title={[data.node.title, t("title")]} />
					<DocumentMeta description={data.node.summary} descriptionHtml={data.node.body} />
					{dataStatus === "pending" && <Loading t={t} />}
					{data.node.type === "competition_case" && <CompetitionCase t={t} node={data.node} />}
					{data.node.type === "decision" && <Decision t={t} node={data.node} />}
					{data.node.type === "draft_technical_notification" && (
						<DraftTechnicalNotification t={t} node={data.node} />
					)}
					{data.node.type === "event" && <Event t={t} node={data.node} />}
					{data.node.type === "food_safety_mission" && <FoodSafetyMission t={t} node={data.node} />}
					{data.node.type === "form" && <Form t={t} node={data.node} />}
					{data.node.type === "gber_information_sheet" && <GberInformationSheet t={t} node={data.node} />}
					{data.node.type === "landing_page" && <LandingPage t={t} node={data.node} />}
					{data.node.type === "listing_page" && <ListingPage t={t} node={data.node} search={search} />}
					{data.node.type === "page" && <Page t={t} node={data.node} />}
					{data.node.type === "press_release" && <PressRelease t={t} node={data.node} />}
					{data.node.type === "publication" && <Publication t={t} node={data.node} />}
					{data.node.type === "search" && <Search t={t} node={data.node} menu={menu} search={search} />}
					{data.node.type === "sitemap" && <Sitemap node={data.node} menu={menu} />}
					{data.node.type === "staff_member" && <StaffMember t={t} node={data.node} />}
				</Fragment>
			);
		}

		return (
			<div className="App">
				<AppHeader t={t} menu={menu} node={data && data.node} />
				<div className="main-layout">
					<aside>
						<SecondLevelMenu menu={menu} node={data && data.node} />
					</aside>
					<main
						className={
							data && data.node
								? `ct_${data.node.type} url_${data.node.alias.substr(1).replace(/\//g, "_")}`
								: undefined
						}
						lang={(data && data.node && data.node.language) || "en"}
					>
						{content}
					</main>
				</div>
				<AppFooter t={t} blocks={blocks} />
			</div>
		);
	}
}
