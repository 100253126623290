import React from "react";
import PropTypes from "prop-types";

const RelatedLinks = ({ t, relatedLinks }) => {
	if (!relatedLinks || !relatedLinks.length) return null;
	return (
		<div className="RelatedLinks">
			<h4>{t("RelatedLinks")}</h4>
			<ul>
				{relatedLinks.map((link, index) => (
					<li key={index}>
						<a href={link.url} rel="external noopener noreferrer" target="_blank">
							{link.title ? link.title : link.url}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export const relatedLinksPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		url: PropTypes.string.isRequired,
		title: PropTypes.string
	})
);

RelatedLinks.propTypes = {
	relatedLinks: relatedLinksPropTypes
};

export default React.memo(RelatedLinks);
