import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import MenuLink from "./MenuLink.js";
import { toggleHeight, collapseToFinalHeight } from "./utils.js";

export const renderMenu = (menu, currentUrl = "") => {
	if (Array.isArray(menu)) {
		return (
			<ul>
				{menu.map((item, index) => (
					<li key={index} className={currentUrl.startsWith(item.url) ? "expanded" : ""}>
						{renderMenu(item, currentUrl)}
					</li>
				))}
			</ul>
		);
	} else {
		return (
			<Fragment>
				{menu.children && menu.children.length > 0 && <button className="toggle">&#8645;</button>}
				<MenuLink menuItem={menu} />
				{menu.children && menu.children.length > 0 && renderMenu(menu.children, currentUrl)}
			</Fragment>
		);
	}
};

/**
 * Handles expandability on menu trees via plain JS on the already rendered DOM.
 * This alters the DOM state and will work since the the menu will
 * never re-render since it never changes, as far as react is concerned.
 */
export const handleExpandCollapseTreeDom = e => {
	// Only handle clicks on "button" tags to do expand/collapse.
	if (e.target.tagName.toLowerCase() !== "button") return;

	toggleHeight(e.target.parentElement.querySelector("ul"), 300);
	e.target.parentElement.classList.toggle("expanded");
};

export default class Menu extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		menu: PropTypes.array.isRequired,
		node: PropTypes.object
	};

	handleMenuToggle = () => {
		this.menuToggleDomNode.classList.toggle("expanded");
		toggleHeight(this.menuDomNode);
	};

	closeMenu = () => {
		this.menuToggleDomNode.classList.remove("expanded");
		collapseToFinalHeight(this.menuDomNode);
	};

	componentDidUpdate(prevProps) {
		if (prevProps.node !== this.props.node) {
			this.closeMenu();
		}
	}

	componentDidMount() {
		document.addEventListener("click", this.handlePossibleOutsideClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handlePossibleOutsideClick, false);
	}

	handlePossibleOutsideClick = e => {
		if (
			!this.menuToggleDomNode.contains(e.target) &&
			!this.menuDomNode.contains(e.target) &&
			this.menuToggleDomNode.classList.contains("expanded")
		) {
			this.handleMenuToggle();
		}
	};

	render() {
		const { t, menu, node } = this.props;

		// Do not render component if there is no menu at all.
		if (!menu || !menu.length) return null;

		const currentUrl = (node && node.alias) || "";

		return (
			<Fragment>
				<menu className="Menu" onClick={handleExpandCollapseTreeDom} ref={node => (this.menuDomNode = node)}>
					{renderMenu(menu, currentUrl)}
				</menu>
				<button
					className="menu-toggle"
					onClick={this.handleMenuToggle}
					ref={node => (this.menuToggleDomNode = node)}
				>
					<span>{t("Menu.toggle")}</span>
				</button>
			</Fragment>
		);
	}
}
