import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { relatedDocumentsPropTypes } from "common/RelatedDocuments.js";
import { relatedContentPropTypes } from "common/RelatedContent.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import RelatedDocuments from "common/RelatedDocuments.js";
import RelatedContent from "common/RelatedContent.js";
import Carousel from "common/Carousel.js";

const LandingPage = ({ t, node }) => {
	return (
		<Fragment>
			<h1>{node.title}</h1>
			<Carousel t={t} items={node.carousel} />
			{node.featuredContent && <ClickableDrupalContent content={node.featuredContent} className="featured" />}
			<ClickableDrupalContent content={node.body} />
			<RelatedDocuments t={t} relatedDocuments={node.relatedDocuments} />
			{node.relatedContentSummary && <ClickableDrupalContent content={node.relatedContentSummary} />}
			<RelatedContent
				t={t}
				relatedContent={node.relatedContent}
				maxSlidesToShow={node.alias === "/home" ? 4 : 3}
			/>
		</Fragment>
	);
};

const landingPagePropTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	featuredContent: PropTypes.string,
	body: PropTypes.string.isRequired,
	relatedDocuments: relatedDocumentsPropTypes,
	relatedContentSummary: PropTypes.string,
	relatedContent: relatedContentPropTypes
};

LandingPage.propTypes = {
	t: PropTypes.func.isRequired,
	node: PropTypes.shape(landingPagePropTypes)
};

export default React.memo(LandingPage);
