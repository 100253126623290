import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { renderDocument, documentPropTypes } from "common/RelatedDocuments.js";

export default class DocumentsNodes extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		nodes: PropTypes.arrayOf(documentPropTypes).isRequired
	};
	render() {
		const { t, nodes } = this.props;
		return (
			<Fragment>
				{nodes.map((node, index) => (
					<li key={index}>{renderDocument(t, node)}</li>
				))}
			</Fragment>
		);
	}
}
