import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import NodeImage from "common/NodeImage.js";
import NodeMeta from "common/NodeMeta.js";
import { renderUrl } from "common/utils.js";
import SocialShare from "common/SocialShare.js";
import { imagePropTypes } from "common/Image.js";
import Wrapper from "common/Wrapper.js";
import Term, { termPropTypes } from "common/Term.js";

export default class StaffMember extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.shape({
			type: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			alias: PropTypes.string.isRequired,
			language: PropTypes.string.isRequired,
			languages: PropTypes.arrayOf(PropTypes.string).isRequired,
			body: PropTypes.string,
			department: termPropTypes.isRequired,
			position: PropTypes.string,
			image: imagePropTypes,
			email: PropTypes.string,
			phone: PropTypes.string,
			attachment: PropTypes.shape({
				description: PropTypes.string.isRequired,
				url: PropTypes.string.isRequired
			})
		})
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<NodeMeta t={t} node={node} />
				<h1>{node.title}</h1>
				{node.image && <NodeImage image={node.image} />}
				<Wrapper className="NodeMetadataWrapper">
					{node.body && (
						<div className="metadata">
							<div className="label">{t("StaffMember.responsibilities")}</div>
							<div>
								<ClickableDrupalContent content={node.body} />
							</div>
						</div>
					)}
					<div className="metadata">
						<div className="label">{t("StaffMember.department")}</div>
						<div>
							<Term term={node.department} />
						</div>
					</div>
					{node.position && (
						<div className="metadata">
							<div className="label">{t("StaffMember.position")}</div>
							<div>{node.position}</div>
						</div>
					)}
					{node.email && (
						<div className="metadata">
							<div className="label">{t("StaffMember.email")}</div>
							<div>{node.email}</div>
						</div>
					)}
					{node.phone && (
						<div className="metadata">
							<div className="label">{t("StaffMember.phone")}</div>
							<div>
								<a href={`tel:${node.phone}`}>{node.phone}</a>
							</div>
						</div>
					)}
					{node.attachment && (
						<div className="metadata">
							<div className="label">{t("StaffMember.attachment")}</div>
							<div>
								<a href={node.attachment.url}>{node.attachment.description}</a>
							</div>
						</div>
					)}
				</Wrapper>
				<SocialShare t={t} url={renderUrl(node.alias, node.language)} />
			</Fragment>
		);
	}
}
