import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import history from "skeleton/history.js";
import { isExternalLink, isCmsLink, isStaticAssetLink, isAnchorLink, isMailLink, isTelLink, toggleHeight } from "./utils.js";
import { getImageDerivative } from "./Image.js";

function getClickedAnchor(target) {
	if (!target || !target.tagName) return undefined;
	if (target.tagName === "BODY") return undefined;
	if (target.tagName === "A") return target;
	return getClickedAnchor(target.parentElement);
}

export function isExpandableAnchorElement(element) {
	if (!isAnchorLink(element.getAttribute("href"))) return false;
	return (
		element.parentElement &&
		element.parentElement.classList.contains("expandable") &&
		element.parentElement.querySelector("a:first-child") === element
	);
}

/**
 * This component renders rich text (HTML) which comes directly from Drupal.
 * This is obviously unsafe (hence the usage of dangerouslySetInnerHTML), bypasses
 * react and depends on the Drupal content managers doing a good job.
 *
 * We need to be doing some JS enhancements/operations on that rich text, and since
 * we do not parse the contents of that rich text in order to "translate" it to
 * react components, we do all of them using JS on top of the already rendered HTML.
 * We currently:
 * - Handle clicks to external or internal content.
 * - Handle clicks on toggler areas on top of some specially crafted HTML structure.
 *   This works because this component never updates itself, thus expanded state
 *   is retained on the DOM.
 */
export default class ClickableDrupalContent extends PureComponent {
	static propTypes = {
		content: PropTypes.string.isRequired,
		className: PropTypes.string
	};

	handleClick = e => {
		const anchor = getClickedAnchor(e.target);
		// Only handle clicks on "a" tags.
		if (!anchor) return;

		// Handle ad-hoc expandability on "components" which are encoded in Drupal HTML.
		// This alters the DOM state and will work since the ClickableDrupalContent will
		// never re-render since it never changes, as far as react is concerned.
		if (isExpandableAnchorElement(anchor)) {
			toggleHeight(anchor.parentElement.querySelector(".expandable-content"), 300);
			anchor.parentElement.classList.toggle("expanded");
			e.preventDefault();
			return;
		}

		// Handle various types of links.
		const href = anchor.getAttribute("href");

		// These should be handled by browser.
		if (isExternalLink(href)) return;
		if (isCmsLink(href)) return;
		if (isStaticAssetLink(href)) return;
		if (isAnchorLink(href)) return;
		if (isMailLink(href)) return;
		if (isTelLink(href)) return;

		// Link to react which should be handled by react.
		e.preventDefault();
		history.push(href);
	};

	/**
	 * Mutates the rendered HTML content and makes anchors with rel="external"
	 * or class="external" to open in a new browser window. In addition it sets
	 * some other rel attributes such as noopener and noreferrer.
	 *
	 * Another approach would be to open in a new window via external link click
	 * detection with isExternalLink().
	 */
	makeExternalLinksOpenInNewWindow = () => {
		this.domNode.querySelectorAll('a[rel="external"]:not([target]),.external:not([target])').forEach(link => {
			link.setAttribute("target", "_blank");
			link.setAttribute("rel", "external noopener noreferrer");
		});
	};

	/**
	 * Mutates the rendered HTML content and, when possible, wraps images with
	 * appropriate picture and source elements.
	 */
	serveWebpWhenPossible = () => {
		this.domNode.querySelectorAll("img[data-entity-type][data-entity-uuid]").forEach(image => {
			// Check whether the image is already wrapped in a picture. This
			// cannot currently happen from the backend.
			if (image.parentNode.tagName === "PICTURE") return; // continue
			// Wrap img in a picture.
			const picture = document.createElement("picture");
			image.parentNode.insertBefore(picture, image);
			// Create a source and add it.
			const source = document.createElement("source");
			source.setAttribute("srcSet", getImageDerivative(image.getAttribute("src"), "webp"));
			source.setAttribute("type", "image/webp");
			picture.appendChild(source);
			// Add image.
			picture.appendChild(image);
		});
	};

	componentDidMount() {
		this.makeExternalLinksOpenInNewWindow();
		this.serveWebpWhenPossible();
	}

	componentDidUpdate() {
		this.makeExternalLinksOpenInNewWindow();
		this.serveWebpWhenPossible();
	}

	render() {
		const { content, className } = this.props;
		return (
			<div
				className={"ClickableDrupalContent" + (className ? " " + className : "")}
				onClick={this.handleClick}
				ref={node => (this.domNode = node)}
				dangerouslySetInnerHTML={{ __html: content }}
			/>
		);
	}
}
