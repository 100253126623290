import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";

export default class CompetitionCasesSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired
	};

	render() {
		const { t } = this.props;
		return (
			<Fragment>
				<div className="field-type-text search">
					<label htmlFor="field-name-q">{t("ListingPage.competition_cases.search")}</label>
					<input id="field-name-q" type="text" maxLength="64" name="q" />
				</div>
			</Fragment>
		);
	}
}
