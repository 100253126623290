import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { termPropTypes } from "common/Term.js";

export default class DocumentsSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		categories: PropTypes.arrayOf(termPropTypes),
		years: PropTypes.array.isRequired,
		countries: PropTypes.array.isRequired
	};

	render() {
		const { t, categories, years, countries } = this.props;
		return (
			<Fragment>
				<div className="field-type-text">
					<label htmlFor="field-name-name">{t("ListingPage.documents.name")}</label>
					<input id="field-name-name" type="text" maxLength="64" name="name" />
				</div>
				<div className="field-type-text">
					<label htmlFor="field-name-number">{t("ListingPage.documents.number")}</label>
					<input id="field-name-number" type="text" maxLength="64" name="number" />
				</div>
				{categories && (
					<div className="field-type-select">
						<label htmlFor="field-name-category">{t("ListingPage.documents.category")}</label>
						<select id="field-name-category" name="category">
							<option value="">{t("ListingPage.documents._category")}</option>
							{categories.map(term => (
								<option key={term.id} value={term.id}>
									{term.title}
								</option>
							))}
						</select>
					</div>
				)}
				<div className="field-type-text">
					<label htmlFor="field-name-type">{t("ListingPage.documents.type")}</label>
					<input id="field-name-type" type="text" maxLength="64" name="type" />
				</div>
				<div className="field-type-text">
					<label htmlFor="field-name-caseName">{t("ListingPage.documents.caseName")}</label>
					<input id="field-name-caseName" type="text" maxLength="64" name="caseName" />
				</div>
				<div className="field-type-text">
					<label htmlFor="field-name-caseNumber">{t("ListingPage.documents.caseNumber")}</label>
					<input id="field-name-caseNumber" type="text" maxLength="64" name="caseNumber" />
				</div>
				<div className="field-type-text">
					<label htmlFor="field-name-market">{t("ListingPage.documents.market")}</label>
					<input id="field-name-market" type="text" maxLength="64" name="market" />
				</div>
				<div className="field-type-text">
					<label htmlFor="field-name-oldMarket">{t("ListingPage.documents.oldMarket")}</label>
					<input id="field-name-oldMarket" type="text" maxLength="64" name="oldMarket" />
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-country">{t("ListingPage.documents.country")}</label>
					<select id="field-name-country" name="country">
						<option value="">{t("ListingPage.documents._country")}</option>
						{countries
							.map(country => country.split("|"))
							.map(country => (
								<option key={country[0]} value={country[0]}>
									{country[1]}
								</option>
							))}
					</select>
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-year">{t("ListingPage.documents.year")}</label>
					<select id="field-name-year" name="year">
						<option value="">{t("ListingPage.documents._year")}</option>
						{years.map(year => (
							<option key={year}>{year}</option>
						))}
					</select>
				</div>
			</Fragment>
		);
	}
}
