import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import DocumentTitle from "./DocumentTitle.js";

export default class Error5xx extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		retry: PropTypes.func.isRequired
	};

	render() {
		const { t, retry } = this.props;
		return (
			<div className="Error5xx error">
				<DocumentTitle title={[t("Error5xx.title"), t("title")]} />
				<h3>{t("Error5xx.header")}</h3>
				<p>{t("Error5xx.content")}</p>
				<button onClick={retry}>{t("please.retry")}</button>
			</div>
		);
	}
}
