import { PureComponent } from "react";
import PropTypes from "prop-types";

export default class DocumentTitle extends PureComponent {
	static propTypes = {
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
		separator: PropTypes.string.isRequired
	};

	static defaultProps = {
		separator: " | "
	};

	getTitle(props = this.props) {
		const { title, separator } = props;
		if (typeof title === "string") {
			return title;
		}
		if (Array.isArray(title)) {
			return title.join(separator);
		}
		return undefined;
	}

	render() {
		const title = this.getTitle();
		if (title !== undefined) document.title = title;
		return null;
	}
}
