import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import DocumentTitle from "./DocumentTitle.js";
import { NavLink } from "react-router-dom";

export default class Error404 extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired
	};

	render() {
		const { t } = this.props;
		return (
			<div className="Error404 error">
				<DocumentTitle title={[t("Error404.title"), t("title")]} />
				<h3>{t("Error404.header")}</h3>
				<p>
					{t("Error404.content")
						.split("\n")
						.map((text, index) => (
							<Fragment key={index}>
								{index > 0 && <br />}
								{text}
							</Fragment>
						))}
				</p>
				<nav>
					<NavLink className="home" to="/">
						{t("Error404.links.home")}
					</NavLink>
					<NavLink className="search" to="/search">
						{t("Error404.links.search")}
					</NavLink>
					<NavLink className="sitemap" to="/sitemap">
						{t("Error404.links.sitemap")}
					</NavLink>
				</nav>
			</div>
		);
	}
}
