import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { termPropTypes } from "common/Term.js";

export default class PressReleasesSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		themes: PropTypes.arrayOf(termPropTypes).isRequired,
		languages: PropTypes.array.isRequired,
		years: PropTypes.array.isRequired
	};

	render() {
		const { t, themes, languages, years } = this.props;
		return (
			<Fragment>
				<div className="field-type-text search">
					<label htmlFor="field-name-q">{t("ListingPage.press_releases.search")}</label>
					<input id="field-name-q" type="text" maxLength="64" name="q" />
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-theme">{t("ListingPage.press_releases.theme")}</label>
					<select id="field-name-theme" name="theme">
						<option value="">{t("ListingPage.press_releases._theme")}</option>
						{themes.map(term => (
							<option key={term.id} value={term.id}>
								{term.title}
							</option>
						))}
					</select>
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-language">{t("ListingPage.press_releases.language")}</label>
					<select id="field-name-language" name="language">
						<option value="">{t("ListingPage.press_releases._language")}</option>
						{languages
							.map(language => language.split("|"))
							.map(language => (
								<option key={language[0]} value={language[0]}>
									{language[1]}
								</option>
							))}
					</select>
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-year">{t("ListingPage.press_releases.year")}</label>
					<select id="field-name-year" name="year">
						<option value="">{t("ListingPage.press_releases._year")}</option>
						{years.map(year => (
							<option key={year}>{year}</option>
						))}
					</select>
				</div>
			</Fragment>
		);
	}
}
