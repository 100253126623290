import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import DateRange from "common/DateRange.js";

export default class EventsNodes extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		nodes: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				alias: PropTypes.string.isRequired,
				startDate: PropTypes.number.isRequired,
				endDate: PropTypes.number.isRequired,
				location: PropTypes.string
			})
		).isRequired
	};
	render() {
		const { t, nodes } = this.props;
		return (
			<Fragment>
				{nodes.map((node, index) => (
					<li key={index}>
						<NavLink to={node.alias}>{node.title}</NavLink>
						<div>
							<DateRange t={t} startDate={node.startDate} endDate={node.endDate} />
							{node.location && <span>{node.location}</span>}
						</div>
					</li>
				))}
			</Fragment>
		);
	}
}
