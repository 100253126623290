import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class Loading extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired
	};

	render() {
		const { t } = this.props;
		return (
			<div className="Loading">
				<div className="sk-folding-cube">
					<div className="sk-cube1 sk-cube"></div>
					<div className="sk-cube2 sk-cube"></div>
					<div className="sk-cube4 sk-cube"></div>
					<div className="sk-cube3 sk-cube"></div>
				</div>
				{t("loading")}
			</div>
		);
	}
}
