import React from "react";
import PropTypes from "prop-types";
import { getHumanReadableFileSize } from "./utils.js";

const Attachments = ({ t, attachments }) => {
	if (!attachments || !attachments.length) return null;
	return (
		<div className="Attachments">
			<h4>{t("Attachments")}</h4>
			<ul>
				{attachments.map((attachment, index) => {
					const { language, description, url, type, size } = attachment;
					return (
						<li key={index}>
							<a href={url}>
								{description}
								<div>
									{language.toUpperCase()} ({type.toUpperCase()}, {getHumanReadableFileSize(size)})
								</div>
							</a>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export const attachmentsPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		language: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		size: PropTypes.number.isRequired,
		type: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired
	})
);

Attachments.propTypes = {
	t: PropTypes.func.isRequired,
	attachments: attachmentsPropTypes
};

export default React.memo(Attachments);
