import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { decodeQuery, encodeQuery } from "./utils.js";

export default class Paginator extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		search: PropTypes.string,
		page: PropTypes.number.isRequired,
		nodesPerPage: PropTypes.number.isRequired,
		nodesCount: PropTypes.number.isRequired
	};

	buildUrl = page => {
		const urlParams = decodeQuery(this.props.search);
		delete urlParams.page;
		if (page > 1) urlParams.page = page;
		return "?" + encodeQuery(urlParams);
	};

	/**
	 * Returns the last page number, in base 1.
	 */
	getLastPage = () => Math.ceil(this.props.nodesCount / this.props.nodesPerPage);

	render() {
		const { t, page: currentPage, nodesPerPage, nodesCount } = this.props;

		// Calcualte total amount of pages.
		const pagesCount = Math.ceil(nodesCount / nodesPerPage);

		// Will collect pagination numbers. Base 1.
		const paginatorPages = [];

		// How many numbered pagination links to display. Odd numbers work best.
		const paginationLinksMax = 5;
		// The amount of displayed pagination links should not be greater than the total pages.
		const paginationLinks = Math.min(paginationLinksMax, pagesCount);
		// Start display at half amount of links before the current page.
		let paginationLinksStart = Math.round(currentPage - paginationLinks / 2);
		// If overflown to left, adjust appropriately.
		if (paginationLinksStart < 1) paginationLinksStart = 1;
		// If overflown to right, adjust appropriately.
		if (paginationLinksStart + paginationLinks > pagesCount)
			paginationLinksStart = pagesCount - paginationLinks + 1;
		// Fill in pagination links.
		for (let i = 0; i < paginationLinks; i++) {
			paginatorPages.push(paginationLinksStart + i);
		}

		const renderEmptySummary = () => <div className="summary">{t("Paginator.empty")}</div>;
		const renderSummary = () => (
			<div className="summary">
				{t("Paginator.summary", {
					from: (currentPage - 1) * nodesPerPage + 1,
					to: Math.min(nodesCount, (currentPage - 1) * nodesPerPage + nodesPerPage),
					total: nodesCount
				})}
			</div>
		);
		const renderPages = () => (
			<Fragment>
				<h4>{t("Paginator.page")}</h4>
				<ul className="pagination">
					<li className="arrow">
						{currentPage === 1 ? (
							<span className="first">{t("Paginator.first")}</span>
						) : (
							<NavLink to={this.buildUrl(1)} isActive={() => false} className="first">
								{t("Paginator.first")}
							</NavLink>
						)}
					</li>
					<li className="arrow">
						{currentPage === 1 ? (
							<span className="previous">{t("Paginator.previous")}</span>
						) : (
							<NavLink to={this.buildUrl(currentPage - 1)} isActive={() => false} className="previous">
								{t("Paginator.previous")}
							</NavLink>
						)}
					</li>
					{paginatorPages.map(page => (
						<li key={page} className={currentPage === page ? "active" : ""}>
							<NavLink to={this.buildUrl(page)} isActive={() => false}>
								{page}
							</NavLink>
						</li>
					))}
					<li className="arrow">
						{currentPage === pagesCount ? (
							<span className="next">{t("Paginator.next")}</span>
						) : (
							<NavLink to={this.buildUrl(currentPage + 1)} isActive={() => false} className="next">
								{t("Paginator.next")}
							</NavLink>
						)}
					</li>
					<li className="arrow">
						{currentPage === pagesCount ? (
							<span className="last">{t("Paginator.last")}</span>
						) : (
							<NavLink to={this.buildUrl(pagesCount)} isActive={() => false} className="last">
								{t("Paginator.last")}
							</NavLink>
						)}
					</li>
				</ul>
			</Fragment>
		);

		return (
			<div className="Paginator">
				{nodesCount === 0 && renderEmptySummary()}
				{nodesCount > 0 && pagesCount > 1 && renderPages()}
				{nodesCount > 0 && renderSummary()}
			</div>
		);
	}
}
