import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { termPropTypes } from "common/Term.js";

export default class DecisionsSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		themes: PropTypes.arrayOf(termPropTypes),
		countries: PropTypes.array.isRequired,
		years: PropTypes.array.isRequired
	};

	render() {
		const { t, themes, countries, years } = this.props;
		return (
			<Fragment>
				<div className="field-type-text search">
					<label htmlFor="field-name-q">{t("ListingPage.decisions.search")}</label>
					<input id="field-name-q" type="text" maxLength="64" name="q" />
				</div>
				{themes && (
					<div className="field-type-select">
						<label htmlFor="field-name-theme">{t("ListingPage.decisions.theme")}</label>
						<select id="field-name-theme" name="theme">
							<option value="">{t("ListingPage.decisions._theme")}</option>
							{themes.map(term => (
								<option key={term.id} value={term.id}>
									{term.title}
								</option>
							))}
						</select>
					</div>
				)}
				<div className="field-type-select">
					<label htmlFor="field-name-country">{t("ListingPage.decisions.country")}</label>
					<select id="field-name-country" name="country">
						<option value="">{t("ListingPage.decisions._country")}</option>
						{countries
							.map(country => country.split("|"))
							.map(country => (
								<option key={country[0]} value={country[0]}>
									{country[1]}
								</option>
							))}
					</select>
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-year">{t("ListingPage.decisions.year")}</label>
					<select id="field-name-year" name="year">
						<option value="">{t("ListingPage.decisions._year")}</option>
						{years.map(year => (
							<option key={year}>{year}</option>
						))}
					</select>
				</div>
			</Fragment>
		);
	}
}
