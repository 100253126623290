import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default class AppLogo extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired
	};

	render() {
		const { t } = this.props;
		return (
			<div className="AppLogo esa-logo">
				<h2>
					<Link to="/">{t("title")}</Link>
				</h2>
			</div>
		);
	}
}
