import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import DocumentTitle from "./DocumentTitle.js";

export default class ErrorNetwork extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		retry: PropTypes.func.isRequired
	};

	render() {
		const { t, retry } = this.props;
		return (
			<div className="ErrorNetwork error">
				<DocumentTitle title={[t("ErrorNetwork.title"), t("title")]} />
				<h3>{t("ErrorNetwork.header")}</h3>
				<p>{t("ErrorNetwork.content")}</p>
				<button onClick={retry}>{t("please.retry")}</button>
			</div>
		);
	}
}
