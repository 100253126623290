import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";

export default class DraftTechnicalNotificationsSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		years: PropTypes.array.isRequired
	};

	render() {
		const { t, years } = this.props;
		return (
			<Fragment>
				<div className="field-type-text search">
					<label htmlFor="field-name-q">{t("ListingPage.food_safety_missions.search")}</label>
					<input id="field-name-q" type="text" maxLength="64" name="q" />
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-year">{t("ListingPage.draft_technical_notifications.year")}</label>
					<select id="field-name-year" name="year">
						<option value="">{t("ListingPage.draft_technical_notifications._year")}</option>
						{years.map(year => (
							<option key={year}>{year}</option>
						))}
					</select>
				</div>
			</Fragment>
		);
	}
}
