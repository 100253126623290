import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { renderDate, renderDateIso } from "./utils.js";

export default class NodeDate extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		date: PropTypes.number
	};

	render() {
		const { t, date } = this.props;
		if (!date) return null;
		return <time date={renderDateIso(date).substr(0, 10)}>{renderDate(date, t("locale.full"))}</time>;
	}
}
