import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import NodeDate from "common/NodeDate.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";

export default class DecisionsNodes extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		nodes: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				alias: PropTypes.string.isRequired,
				number: PropTypes.string.isRequired,
				date: PropTypes.number.isRequired,
				decisionType: PropTypes.string,
				decisionOjReference: PropTypes.string,
				decisionEftaProcedure: PropTypes.string,
				decisionEeaSupplement: PropTypes.string
			})
		).isRequired
	};
	render() {
		const { t, nodes } = this.props;
		return (
			<Fragment>
				{nodes.map((node, index) => (
					<li key={index}>
						<NavLink to={node.alias}>{node.title}</NavLink>
						<div>
							<span>
								{t("Decision.number")}: {node.number}
							</span>
							<NodeDate t={t} date={node.date} />
							{node.decisionType && (
								<span>
									{t("Decision.decisionType")}: {node.decisionType}
								</span>
							)}
							{node.decisionOjReference && (
								<div>
									{t("Decision.decisionOjReference")}:{" "}
									<ClickableDrupalContent content={node.decisionOjReference} />
								</div>
							)}
							{node.decisionEftaProcedure && (
								<div>
									{t("Decision.decisionEftaProcedure")}:{" "}
									<ClickableDrupalContent content={node.decisionEftaProcedure} />
								</div>
							)}
							{node.decisionEeaSupplement && (
								<div>
									{t("Decision.decisionEeaSupplement")}:{" "}
									<ClickableDrupalContent content={node.decisionEeaSupplement} />
								</div>
							)}
						</div>
					</li>
				))}
			</Fragment>
		);
	}
}
