import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Attachments, { attachmentsPropTypes } from "common/Attachments.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import RelatedDocuments, { relatedDocumentsPropTypes } from "common/RelatedDocuments.js";
import { renderUrl } from "common/utils.js";
import SocialShare from "common/SocialShare.js";
import NodeMeta from "common/NodeMeta.js";
import { termPropTypes } from "common/Term.js";
import Wrapper from "common/Wrapper.js";
import NodeDate from "common/NodeDate.js";

const DraftTechnicalNotification = ({ t, node }) => (
	<Fragment>
		<NodeMeta t={t} node={node} />
		<h1>{node.title}</h1>
		{node.body && <ClickableDrupalContent content={node.body} />}
		<Attachments t={t} attachments={node.attachments} />
		<RelatedDocuments t={t} relatedDocuments={node.relatedDocuments} />
		<Wrapper className="NodeMetadataWrapper">
			{node.country && (
				<div className="metadata">
					<div className="label">{t("DraftTechnicalNotification.country")}</div>
					<div>{node.country.name}</div>
				</div>
			)}
			<div className="metadata">
				<div className="label">{t("DraftTechnicalNotification.year")}</div>
				<div>{node.year}</div>
			</div>
			<div className="metadata">
				<div className="label">{t("DraftTechnicalNotification.number")}</div>
				<div>{node.number}</div>
			</div>
			{node.originalTitle && (
				<div className="metadata">
					<div className="label">{t("DraftTechnicalNotification.originalTitle")}</div>
					<div>{node.originalTitle}</div>
				</div>
			)}
			{node.standstillEnd && (
				<div className="metadata">
					<div className="label">{t("DraftTechnicalNotification.standstillEnd")}</div>
					<div>
						<NodeDate t={t} date={node.standstillEnd} />
					</div>
				</div>
			)}
			{node.adopted && (
				<div className="metadata">
					<div className="label">{t("DraftTechnicalNotification.adopted")}</div>
					<NodeDate t={t} date={node.adopted} />
				</div>
			)}
		</Wrapper>
		<SocialShare t={t} url={renderUrl(node.alias, node.language)} />
	</Fragment>
);

const draftTechnicalNotificationPropTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	body: PropTypes.string,
	attachments: attachmentsPropTypes,
	relatedDocuments: relatedDocumentsPropTypes,
	year: PropTypes.number.isRequired,
	theme: termPropTypes.isRequired,
	country: PropTypes.shape({
		code: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	}),
	originalTitle: PropTypes.string,
	number: PropTypes.string.isRequired,
	standstillEnd: PropTypes.number,
	adopted: PropTypes.number
};

DraftTechnicalNotification.propTypes = {
	t: PropTypes.func.isRequired,
	node: PropTypes.shape(draftTechnicalNotificationPropTypes)
};

export default React.memo(DraftTechnicalNotification);
