import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { termPropTypes } from "common/Term.js";

export default class PublicationsSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		types: PropTypes.arrayOf(termPropTypes),
		themes: PropTypes.arrayOf(termPropTypes),
		years: PropTypes.array.isRequired
	};

	render() {
		const { t, types, themes, years } = this.props;
		return (
			<Fragment>
				<div className="field-type-text search">
					<label htmlFor="field-name-q">{t("ListingPage.publications.search")}</label>
					<input id="field-name-q" type="text" maxLength="64" name="q" />
				</div>
				{types && (
					<div className="field-type-select">
						<label htmlFor="field-name-type">{t("ListingPage.publications.type")}</label>
						<select id="field-name-type" name="type">
							<option value="">{t("ListingPage.publications._type")}</option>
							{types.map(term => (
								<option key={term.id} value={term.id}>
									{term.title}
								</option>
							))}
						</select>
					</div>
				)}
				{themes && (
					<div className="field-type-select">
						<label htmlFor="field-name-theme">{t("ListingPage.decisions.theme")}</label>
						<select id="field-name-theme" name="theme">
							<option value="">{t("ListingPage.decisions._theme")}</option>
							{themes.map(term => (
								<option key={term.id} value={term.id}>
									{term.title}
								</option>
							))}
						</select>
					</div>
				)}
				<div className="field-type-select">
					<label htmlFor="field-name-year">{t("ListingPage.publications.year")}</label>
					<select id="field-name-year" name="year">
						<option value="">{t("ListingPage.publications._year")}</option>
						{years.map(year => (
							<option key={year}>{year}</option>
						))}
					</select>
				</div>
			</Fragment>
		);
	}
}
