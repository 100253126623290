import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Attachments, { attachmentsPropTypes } from "common/Attachments.js";
import RelatedContent, { relatedContentPropTypes } from "common/RelatedContent.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import RelatedDocuments, { relatedDocumentsPropTypes } from "common/RelatedDocuments.js";
import RelatedLinks, { relatedLinksPropTypes } from "common/RelatedLinks.js";
import NodeImage from "common/NodeImage.js";
import { renderUrl } from "common/utils.js";
import SocialShare from "common/SocialShare.js";
import NodeMeta from "common/NodeMeta.js";
import Term, { termPropTypes } from "common/Term.js";
import Wrapper from "common/Wrapper.js";
import { imagePropTypes } from "common/Image.js";

const Publication = ({ t, node }) => (
	<Fragment>
		<NodeMeta t={t} node={node} />
		<h1>{node.title}</h1>
		<NodeImage image={node.image} />
		{node.body && <ClickableDrupalContent content={node.body} />}
		<Attachments t={t} attachments={node.attachments} />
		<RelatedDocuments t={t} relatedDocuments={node.relatedDocuments} />
		<RelatedLinks t={t} relatedLinks={node.relatedLinks} />
		<Wrapper className="NodeMetadataWrapper">
			<div className="metadata">
				<div className="label">{t("Term.publication_types")}</div>
				<div>
					<Term term={node.publicationType} />
				</div>
			</div>
			{node.publicationAmendment && (
				<div className="metadata">
					<div className="label">{t("Publication.publicationAmendment")}</div>
					<div>{node.publicationAmendment}</div>
				</div>
			)}
			{node.publicationPeriod && (
				<div className="metadata">
					<div className="label">{t("Publication.publicationPeriod")}</div>
					<div>{node.publicationPeriod}</div>
				</div>
			)}
		</Wrapper>
		<SocialShare t={t} url={renderUrl(node.alias, node.language)} />
		<RelatedContent t={t} relatedContent={node.relatedContent} />
	</Fragment>
);

const publicationPropTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	body: PropTypes.string,
	image: imagePropTypes,
	attachments: attachmentsPropTypes,
	relatedContent: relatedContentPropTypes,
	relatedDocuments: relatedDocumentsPropTypes,
	relatedLinks: relatedLinksPropTypes,
	date: PropTypes.number.isRequired,
	theme: termPropTypes,
	publicationType: termPropTypes.isRequired,
	publicationAmendment: PropTypes.string,
	publicationPeriod: PropTypes.string
};

Publication.propTypes = {
	t: PropTypes.func.isRequired,
	node: PropTypes.shape(publicationPropTypes)
};

export default React.memo(Publication);
