import axios from "axios";

export function loadNodeFromServer(url, search) {
	return axios
		.get(`api/node?url=${encodeURIComponent(url)}${search ? "&search=" + encodeURIComponent(search) : ""}`)
		.then(result => {
			if (typeof result.data !== "object") {
				throw new Error("Response not an object");
			}
			return result.data;
		});
}

export function loadBlocksFromServer(titles) {
	return axios.get(`api/blocks?titles=${encodeURIComponent(titles)}`).then(result => {
		return result.data;
	});
}

export function loadMainMenuFromServer() {
	return axios.get("api/menu").then(result => {
		return result.data;
	});
}
