import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";

export default class GberInformationSheetsSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		countries: PropTypes.array.isRequired,
		years: PropTypes.array.isRequired
	};

	render() {
		const { t, countries, years } = this.props;
		return (
			<Fragment>
				<div className="field-type-text search">
					<label htmlFor="field-name-q">{t("ListingPage.gber_information_sheets.search")}</label>
					<input id="field-name-q" type="text" maxLength="64" name="q" />
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-country">{t("ListingPage.gber_information_sheets.country")}</label>
					<select id="field-name-country" name="country">
						<option value="">{t("ListingPage.gber_information_sheets._country")}</option>
						{countries
							.map(country => country.split("|"))
							.map(country => (
								<option key={country[0]} value={country[0]}>
									{country[1]}
								</option>
							))}
					</select>
				</div>
				<div className="field-type-select">
					<label htmlFor="field-name-year">{t("ListingPage.gber_information_sheets.year")}</label>
					<select id="field-name-year" name="year">
						<option value="">{t("ListingPage.gber_information_sheets._year")}</option>
						{years.map(year => (
							<option key={year}>{year}</option>
						))}
					</select>
				</div>
			</Fragment>
		);
	}
}
