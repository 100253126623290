import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Term, { termPropTypes } from "common/Term.js";

export default class FoodSafetyMissionsNodes extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		nodes: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				alias: PropTypes.string.isRequired,
				country: PropTypes.shape({
					code: PropTypes.string.isRequired,
					name: PropTypes.string.isRequired
				}).isRequired,
				year: PropTypes.number.isRequired,
				foodSafetyCategory: termPropTypes.isRequired
			})
		).isRequired
	};
	render() {
		const { t, nodes } = this.props;
		return (
			<Fragment>
				{nodes.map((node, index) => (
					<li key={index}>
						<NavLink to={node.alias}>{node.title}</NavLink>
						<div>
							<span>
								{t("FoodSafetyMission.country")}: {node.country.name}
							</span>
							<span>
								{t("FoodSafetyMission.year")}: {node.year}
							</span>
							<span>
								{t("FoodSafetyMission.category")}: <Term term={node.foodSafetyCategory} />
							</span>
						</div>
					</li>
				))}
			</Fragment>
		);
	}
}
