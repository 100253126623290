import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { renderDate, renderDateIso } from "./utils.js";

export default class DateRange extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		startDate: PropTypes.number,
		endDate: PropTypes.number
	};

	render() {
		const { t, startDate, endDate } = this.props;
		if (!startDate) return null;
		return (
			<span className="DateRange">
				<time date={renderDateIso(startDate).substr(0, 10)}>{renderDate(startDate, t("locale.full"))}</time>
				{endDate > startDate && (
					<Fragment>
						<span> - </span>
						<time date={renderDateIso(endDate).substr(0, 10)}>{renderDate(endDate, t("locale.full"))}</time>
					</Fragment>
				)}
			</span>
		);
	}
}
