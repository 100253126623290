const i18n = {
	locale: "en",
	"locale.full": "en-GB",
	title: "ESA",
	"please.retry": "Please try again",
	"please.wait": "Please wait",
	loading: "Loading...",
	deleted: "Deleted",
	edited: "Edited",
	edit: "Edit",
	save: "Save",
	more: "More...",
	less: "Less...",
	next: "Next",
	reset: "Reset",
	apply: "Apply",
	previous: "Previous",
	home: "Home",
	ago: {
		future: "in the future",
		now: "just now",
		seconds: "1 second ago||||%{smart_count} seconds ago",
		minutes: "1 minute ago||||%{smart_count} minutes ago",
		hours: "1 hour ago||||%{smart_count} hours ago",
		days: "1 day ago||||%{smart_count} days ago",
		months: "1 month ago||||%{smart_count} months ago",
		years: "1 year ago||||%{smart_count} years ago"
	},
	"Sitemap.title": "Sitemap",
	Error404: {
		title: "Error 404",
		header: "Sorry. Page not found!",
		content: "We can’t seem to find the content you are looking for.\nThe following links might be able to help.",
		links: {
			home: "Home",
			search: "Search",
			sitemap: "Sitemap"
		}
	},
	Error5xx: {
		title: "Internal Server Error",
		header: "Sorry. It's not you, it's us.",
		content: "The server encountered an internal error or misconfiguration and was unable to complete your request."
	},
	ErrorNetwork: {
		title: "Network Error",
		header: "Network error!",
		content: "The request timed out or you are currently offline."
	},
	"api.error": "An error has occured while communicating with the server. Please try again.",
	"api.pending": "Please wait...",
	Attachments: "Downloads",
	RelatedContent: "You may also be interested in",
	RelatedDocuments: {
		label: "Related Documents",
		date: "Date",
		number: "Doc. number",
		state: "State",
		type: "Doc. type",
		caseName: "Case Name",
		caseNumber: "Case no",
		description: "Description",
		collegeDecision: "College decision",
		collegeDecisionNumber: "College decision number",
		controlSystem: "Control system",
		market: "Market",
		oldMarket: "Old market",
		missionId: "Mission id",
		country: "Country"
	},
	RelatedLinks: "Related Links",
	Publication: {
		publicationAmendment: "Publication Amendment",
		publicationPeriod: "Publication Period"
	},
	PressRelease: {
		pressReleaseNumber: "Press Release Number"
	},
	SocialShare: {
		label: "Share this",
		facebook: "Share on Facebook",
		twitter: "Share on Twitter",
		linkedin: "Share on LinkedIn"
	},
	Menu: {
		toggle: "Toggle Menu"
	},
	Term: {
		departments: "Departments",
		document_categories: "Document category",
		event_types: "Event type",
		publication_types: "Publication type",
		themes: "Theme"
	},
	ListingPage: {
		competition_cases: {
			search: "Text search"
		},
		decisions: {
			search: "Text search",
			theme: "Theme",
			_theme: "Any theme",
			country: "Country",
			_country: "Any country",
			year: "Year",
			_year: "Any year"
		},
		documents: {
			name: "Document name",
			number: "Document number",
			category: "Category",
			_category: "Any category",
			type: "Type",
			caseName: "Case name",
			caseNumber: "Case number",
			market: "Market",
			oldMarket: "Old market",
			country: "Country",
			_country: "Any country",
			year: "Year",
			_year: "Any year"
		},
		draft_technical_notifications: {
			search: "Text search",
			year: "Year",
			_year: "Any year"
		},
		events: {
			search: "Text search",
			from: "Date from",
			to: "Date to",
			type: "Type",
			_type: "Any type"
		},
		food_safety_missions: {
			search: "Text search",
			country: "Country",
			_country: "Any country",
			year: "Year",
			_year: "Any year",
			category: "Category",
			_category: "Any category"
		},
		gber_information_sheets: {
			search: "Text search",
			country: "Country",
			_country: "Any country",
			year: "Year",
			_year: "Any year"
		},
		press_releases: {
			search: "Text search",
			theme: "Theme",
			_theme: "Any theme",
			language: "Translation",
			_language: "Any translation",
			year: "Year",
			_year: "Any year"
		},
		publications: {
			search: "Text search",
			type: "Type",
			_type: "Any type",
			year: "Year",
			_year: "Any year"
		},
		status_database_case: {
			search: "Text search",
			celex_num: "Celex no.",
			eu_ref: "EU Ref.",
			_implStatusNor: "Implementation NOR",
			implStatusNor: "Implementation NOR",
			_implStatusIcel: "Implementation ICE",
			implStatusIcel: "Implementation ICE",
			_implStatusLiech: "Implementation LIE",
			implStatusLiech: "Implementation LIE",
			_actType: "Act status",
			_year: "EEA Compliance date year",
			year: "EEA Compliance date year"
		}
	},
	Decision: {
		number: "Number",
		date: "Date",
		decisionType: "Type",
		decisionOjReference: "OJ Reference",
		decisionEftaProcedure: "EFTA Court procedure",
		decisionEeaSupplement: "EEA Supplement",
		country: "State"
	},
	Form: {
		internal_market_complaint: {
			disclaimer: {
				text_html:
					"<p>You are about to file a complaint to the EFTA Surveillance Authority concerning failure by Norway, Iceland or Liechtenstein to comply with EEA law. Before you start, make sure you have all the information you need at hand before proceeding. If you close the online form before sending the complaint your information will not be saved and you will need to start again.</p><p>When you finish the online form and send your complaint, a copy of the complaint will be sent to your email as well.</p>",
				checkbox_html:
					'I have read the complaint guidelines and the <a  target="_blank" href="/cms/sites/default/files/documents/gopro/Explanatory%20note%20to%20complaint%20form.pdf">Explanatory note</a> and prepared all the information I need to file my complaint',
				continue: "Continue"
			}
		},
		gdpr: {
			title: "Data Protection",
			details_html:
				'I understand that my personal data will be handled in line with ESA’s <a target="_blank" href="/cms/sites/default/files/documents/Rules-on-Data-Protection---EFTA-Surveillance-Authority.pdf">Rules on Data Protection</a> and <a target="_blank" href="/esa-at-a-glance/data-protection/register">Privacy Statement</a> for website users.'
		},
		submit: "Submit",
		submissionStatus: {
			"warning-uploadMaxSize":
				"The maximum total size of files submitted should not exceed %{uploadMaxSize} bytes. Please remove some files and try again.",
			pending: "Please wait while the form is being submitted.",
			error: "An error has occured while trying to submit the form. Please try again.",
			captcha_validation_error: "An error has occured while validating captcha. Please try again."
		}
	},
	GberInformationSheet: {
		country: "Country",
		year: "Year",
		number: "Case number",
		gberAidReference: "Aid GBER Reference"
	},
	CompetitionCase: {
		competitionCaseNumber: "Competition Case Number"
	},
	StaffMember: {
		responsibilities: "Responsibilities",
		department: "Department",
		position: "Position",
		email: "Email",
		phone: "Phone",
		attachment: "Attachment"
	},
	FoodSafetyMission: {
		country: "Country",
		year: "Year",
		category: "Category"
	},
	DraftTechnicalNotification: {
		country: "Country",
		year: "Year",
		originalTitle: "Original title",
		number: "Number",
		standstillEnd: "End of Standstill",
		adopted: "Adopted"
	},
	ContactPersons: {
		email: "Email",
		phone: "Phone",
		mobile: "Mobile"
	},
	Search: {
		title: "Search",
		search: "Text search"
	},
	Paginator: {
		page: "Page",
		empty: "No results found",
		first: "First",
		last: "Last",
		next: "Next",
		previous: "Previous",
		summary: "Showing %{from}-%{to} out of %{total} results"
	},
	files: {
		many: "%{smart_count} files"
	}
};
export default i18n;
