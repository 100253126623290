import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import NodeDate from "common/NodeDate.js";

export default class DraftTechnicalNotificationsNodes extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		nodes: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				alias: PropTypes.string.isRequired,
				country: PropTypes.shape({
					code: PropTypes.string.isRequired,
					name: PropTypes.string.isRequired
				}),
				originalTitle: PropTypes.string,
				number: PropTypes.string.isRequired,
				standstillEnd: PropTypes.number,
				adopted: PropTypes.number
			})
		).isRequired
	};
	render() {
		const { t, nodes } = this.props;
		return (
			<Fragment>
				{nodes.map((node, index) => (
					<li key={index}>
						<NavLink to={node.alias}>{node.title}</NavLink>
						<div>
							{node.country && (
								<span>
									{t("DraftTechnicalNotification.country")}: {node.country.name}
								</span>
							)}
							<span>
								{t("DraftTechnicalNotification.year")}: {node.year}
							</span>
							<span>
								{t("DraftTechnicalNotification.number")}: {node.number}
							</span>
							{node.originalTitle && (
								<span>
									{t("DraftTechnicalNotification.originalTitle")}: {node.originalTitle}
								</span>
							)}
							{node.standstillEnd && (
								<span>
									{t("DraftTechnicalNotification.standstillEnd")}:{" "}
									<NodeDate t={t} date={node.standstillEnd} />
								</span>
							)}
							{node.adopted && (
								<span>
									{t("DraftTechnicalNotification.adopted")}: <NodeDate t={t} date={node.adopted} />
								</span>
							)}
						</div>
					</li>
				))}
			</Fragment>
		);
	}
}
