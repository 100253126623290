import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Attachments, { attachmentsPropTypes } from "common/Attachments.js";
import history from "skeleton/history.js";
import { serializeForm, unserializeForm, applyActiveFieldClass } from "common/utils.js";
import Paginator from "common/Paginator.js";
import CompetitionCasesNodes from "./CompetitionCasesNodes.js";
import CompetitionCasesSearchFormFields from "./CompetitionCasesSearchFormFields.js";
import DecisionsNodes from "./DecisionsNodes.js";
import DecisionsSearchFormFields from "./DecisionsSearchFormFields.js";
import DocumentsNodes from "./DocumentsNodes.js";
import DocumentsSearchFormFields from "./DocumentsSearchFormFields.js";
import DraftTechnicalNotificationsNodes from "./DraftTechnicalNotificationsNodes.js";
import DraftTechnicalNotificationsSearchFormFields from "./DraftTechnicalNotificationsSearchFormFields.js";
import EventsNodes from "./EventsNodes.js";
import EventsSearchFormFields from "./EventsSearchFormFields.js";
import FoodSafetyMissionsNodes from "./FoodSafetyMissionsNodes.js";
import FoodSafetyMissionsSearchFormFields from "./FoodSafetyMissionsSearchFormFields.js";
import GberInformationSheetsNodes from "./GberInformationSheetsNodes.js";
import GberInformationSheetsSearchFormFields from "./GberInformationSheetsSearchFormFields.js";
import PressReleasesNodes from "./PressReleasesNodes.js";
import PressReleasesSearchFormFields from "./PressReleasesSearchFormFields.js";
import ImplementationStatus from "./ImplementationStatus.js";
import ImplementationStatusSearchFormFields from "./ImplementationStatusSearchFormFields";
import PublicationsNodes from "./PublicationsNodes.js";
import PublicationsSearchFormFields from "./PublicationsSearchFormFields.js";
import StaffMembersNodes from "./StaffMembersNodes.js";

export default class ListingPage extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.shape({
			type: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			alias: PropTypes.string.isRequired,
			body: PropTypes.string,
			attachments: attachmentsPropTypes,
			listing: PropTypes.shape({
				fields: PropTypes.object.isRequired,
				data: PropTypes.shape({
					page: PropTypes.number.isRequired,
					nodesPerPage: PropTypes.number.isRequired,
					nodesCount: PropTypes.number.isRequired,
					nodes: PropTypes.arrayOf(
						PropTypes.shape({
							title: PropTypes.string.isRequired,
							alias: PropTypes.string
						})
					).isRequired
				}).isRequired
			}).isRequired,
			listingId: PropTypes.string.isRequired
		}),
		search: PropTypes.string
	};

	onSubmit = e => {
		e.preventDefault();
		history.push("?" + serializeForm(this.formDomNode));
	};

	enhanceDomForm = () => {
		// Possibly on a listing page without a form.
		if (!this.formDomNode) return;

		// Populate form with content from URL.
		unserializeForm(this.formDomNode, this.props.search);

		// Toggle CSS animation related class on focus/blur.
		applyActiveFieldClass(this.formDomNode);
	};

	componentDidMount() {
		this.enhanceDomForm();
	}

	componentDidUpdate(prevProps) {
		// Switched to another listing page in the site.
		if (prevProps.node.alias !== this.props.node.alias) {
			this.enhanceDomForm();
		}
		// Switched to a different search (maybe using browser back and forth).
		if (prevProps.search !== this.props.search) {
			this.enhanceDomForm();
		}
	}

	render() {
		const { t, node, search } = this.props;

		const getFormFields = () => {
			if (node.listingId === "competition_cases") return <CompetitionCasesSearchFormFields t={t} />;
			if (node.listingId === "decisions")
				return (
					<DecisionsSearchFormFields
						t={t}
						themes={node.listing.fields.themes}
						countries={node.listing.fields.countries}
						years={node.listing.fields.years}
					/>
				);
			if (node.listingId === "documents")
				return (
					<DocumentsSearchFormFields
						t={t}
						categories={node.listing.fields.categories}
						countries={node.listing.fields.countries}
						years={node.listing.fields.years}
					/>
				);
			if (node.listingId === "draft_technical_notifications")
				return <DraftTechnicalNotificationsSearchFormFields t={t} years={node.listing.fields.years} />;
			if (node.listingId === "events") return <EventsSearchFormFields t={t} types={node.listing.fields.types} />;
			if (node.listingId === "food_safety_missions")
				return (
					<FoodSafetyMissionsSearchFormFields
						t={t}
						countries={node.listing.fields.countries}
						years={node.listing.fields.years}
						categories={node.listing.fields.categories}
					/>
				);
			if (node.listingId === "gber_information_sheets")
				return (
					<GberInformationSheetsSearchFormFields
						t={t}
						countries={node.listing.fields.countries}
						years={node.listing.fields.years}
					/>
				);
			if (node.listingId === "press_releases")
				return (
					<PressReleasesSearchFormFields
						t={t}
						themes={node.listing.fields.themes}
						languages={node.listing.fields.languages}
						years={node.listing.fields.years}
					/>
				);
			if (node.listingId === "publications")
				return (
					<PublicationsSearchFormFields
						t={t}
						types={node.listing.fields.types}
						themes={node.listing.fields.themes}
						years={node.listing.fields.years}
					/>
				);
				if (node.listingId === "status_database_case")
				return (
					<ImplementationStatusSearchFormFields
						t={t}
						actType={node.listing.fields.actType}
						implStatusIcel={node.listing.fields.implStatusIcel}
						implStatusLiech={node.listing.fields.implStatusLiech}
						implStatusNor={node.listing.fields.implStatusNor}
						years={node.listing.fields.years}
					/>
				);
		};

		const renderForm = () => {
			const fields = getFormFields();
			// Do not render form and action buttons if there are no filters.
			if (!React.Children.toArray(fields).length) return null;
			return (
				<form method="get" onSubmit={this.onSubmit} ref={node => (this.formDomNode = node)}>
					<div className="fields">{fields}</div>
					<div className="actions">
						<input className="button small hollow" type="reset" value={t("reset")} />
						<button className="button small" type="submit">
							{t("apply")}
						</button>
					</div>
				</form>
			);
		};

		const renderPaginator = () => (
			<Paginator
				t={t}
				search={search}
				page={node.listing.data.page}
				nodesPerPage={node.listing.data.nodesPerPage}
				nodesCount={node.listing.data.nodesCount}
			/>
		);

		const renderNodes = () =>
			node.listing.data.nodes.length > 0 && (
				<div>
					<ol
						className={`content ${node.listingId}`}
						start={(node.listing.data.page - 1) * node.listing.data.nodesPerPage + 1}
					>
						{node.listingId === "competition_cases" && (
							<CompetitionCasesNodes nodes={node.listing.data.nodes} />
						)}
						{node.listingId === "decisions" && <DecisionsNodes t={t} nodes={node.listing.data.nodes} />}
						{node.listingId === "documents" && <DocumentsNodes t={t} nodes={node.listing.data.nodes} />}
						{node.listingId === "draft_technical_notifications" && (
							<DraftTechnicalNotificationsNodes t={t} nodes={node.listing.data.nodes} />
						)}
						{node.listingId === "events" && <EventsNodes t={t} nodes={node.listing.data.nodes} />}
						{node.listingId === "food_safety_missions" && (
							<FoodSafetyMissionsNodes t={t} nodes={node.listing.data.nodes} />
						)}
						{node.listingId === "gber_information_sheets" && (
							<GberInformationSheetsNodes t={t} nodes={node.listing.data.nodes} />
						)}
						{node.listingId === "press_releases" && (
							<PressReleasesNodes t={t} nodes={node.listing.data.nodes} />
						)}
						{node.listingId === "publications" && <PublicationsNodes nodes={node.listing.data.nodes} />}
						{node.listingId === "staff_members" && <StaffMembersNodes nodes={node.listing.data.nodes} />}
						{node.listingId === "status_database_case" && <ImplementationStatus nodes={node.listing.data.nodes} />}
					</ol>
				</div>
			);
		return (
			<Fragment>
				<h1>{node.title}</h1>
				{node.body && <ClickableDrupalContent content={node.body} />}
				<Attachments t={t} attachments={node.attachments} />
				{renderForm()}
				{renderPaginator()}
				{renderNodes()}
				{renderPaginator()}
			</Fragment>
		);
	}
}
