import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isCmsLink } from "./utils.js";

export const imagePropTypes = PropTypes.shape({
	url: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired
});

export const getImageDerivative = (url, extension) => {
	const indexOfQuestionmark = url.lastIndexOf("?");
	return indexOfQuestionmark === -1
		? url + "." + extension
		: url.substr(0, indexOfQuestionmark) + "." + extension + url.substr(indexOfQuestionmark);
};

export default class Image extends PureComponent {
	static propTypes = {
		image: imagePropTypes
	};

	render() {
		const { image } = this.props;
		return (
			<picture>
				{isCmsLink(image.url) && <source srcSet={getImageDerivative(image.url, "webp")} type="image/webp" />}
				<img src={image.url} alt={image.alt} width={image.width} height={image.height} loading="lazy" />
			</picture>
		);
	}
}
