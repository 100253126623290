import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import NodeImage from "common/NodeImage.js";
import { imagePropTypes } from "common/Image.js";

export default class CompetitionCasesNodes extends PureComponent {
	static propTypes = {
		nodes: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				alias: PropTypes.string.isRequired,
				summary: PropTypes.string,
				image: imagePropTypes
			})
		).isRequired
	};
	render() {
		const { nodes } = this.props;
		return (
			<Fragment>
				{nodes.map((node, index) => (
					<li key={index}>
						<NavLink to={node.alias}>
							<NodeImage image={node.image} />
							<div className="esa-decor">
								<span className="esa-decorative-line">&nbsp;</span>
								<p>{node.title}</p>
								{node.summary && <div className="summary">{node.summary}</div>}
							</div>
						</NavLink>
					</li>
				))}
			</Fragment>
		);
	}
}
