import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Menu from "common/Menu.js";
import AppLogo from "./AppLogo.js";
import { NavLink } from "react-router-dom";

export default class AppHeader extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		menu: PropTypes.array.isRequired,
		node: PropTypes.object
	};

	render() {
		const { t, menu, node } = this.props;
		return (
			<header className="AppHeader esa-decor">
				<span className="esa-decorative-line">&nbsp;</span>
				<AppLogo t={t} />
				<Menu t={t} menu={menu} node={node} />
				<NavLink to="/search" className="search">
					{t("Search.title")}
				</NavLink>
			</header>
		);
	}
}
