import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { renderUrl } from "./utils.js";

export const shouldRender = languages => languages && languages.length > 1;

export default class Languages extends PureComponent {
	static propTypes = {
		languages: PropTypes.arrayOf(PropTypes.string).isRequired,
		language: PropTypes.string.isRequired,
		alias: PropTypes.string.isRequired
	};

	renderLanguageLabel = language => {
		// Render "nb" as "no" to solve ESA-179.
		if (language === "nb") return "no";
		return language;
	};

	render() {
		const { languages, language, alias } = this.props;
		if (!shouldRender(languages)) return null;
		return (
			<ul className="Languages">
				{languages.map((lang, index) => (
					<li key={index}>
						<NavLink isActive={() => lang === language} to={renderUrl(alias, lang)}>
							{this.renderLanguageLabel(lang)}
						</NavLink>
					</li>
				))}
			</ul>
		);
	}
}
