import React from "react";
import PropTypes from "prop-types";

const Term = ({ term }) => <span className="Term">{term.title}</span>;

export const termPropTypes = PropTypes.shape({
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired
});

Term.propTypes = {
	term: termPropTypes.isRequired
};

export default React.memo(Term);
