import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { termPropTypes } from "common/Term.js";
import { imagePropTypes } from "common/Image.js";
import Image from "common/Image.js";

export default class StaffMembersNodes extends PureComponent {
	static propTypes = {
		nodes: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				alias: PropTypes.string.isRequired,
				department: termPropTypes.isRequired,
				position: PropTypes.string,
				image: imagePropTypes,
				email: PropTypes.string,
				phone: PropTypes.string,
				attachment: PropTypes.shape({
					description: PropTypes.string.isRequired,
					url: PropTypes.string.isRequired
				})
			})
		).isRequired
	};
	render() {
		const { nodes } = this.props;

		// To collect all departments we have in the results.
		const departments = [];

		// Group staff members.
		const staffMembersPerDepartment = nodes.reduce((map, node) => {
			if (!map[node.department.id]) map[node.department.id] = [];
			map[node.department.id].push(node);
			// Collect departments.
			if (!departments.find(department => department.id === node.department.id)) {
				departments.push(node.department);
			}
			return map;
		}, {});

		return (
			<Fragment>
				{departments
					.sort((a, b) => a.weight - b.weight)
					.map((department, index) => (
						<li key={`d-${index}`}>
							<h2>{department.title}</h2>
							<ul>
								{staffMembersPerDepartment[department.id].map((node, index) => (
									<li key={`s-${index}`}>
										<div className="image-wrapper">
											{node.image && <Image image={node.image} />}
										</div>
										<div className="info">
											<div className="personal">
												<NavLink to={node.alias}>{node.title}</NavLink>
												{node.position && <div className="position">{node.position}</div>}
											</div>
											{(node.email || node.phone || node.attachment) && (
												<div className="contact">
													{node.email && <span className="email">{node.email}</span>}
													{node.phone && (
														<a href={`tel:${node.phone}`} className="phone">
															{node.phone}
														</a>
													)}
													{node.attachment && (
														<a href={node.attachment.url} className="download">
															{node.attachment.description}
														</a>
													)}
												</div>
											)}
										</div>
									</li>
								))}
							</ul>
						</li>
					))}
			</Fragment>
		);
	}
}
