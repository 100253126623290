import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { renderMenu, handleExpandCollapseTreeDom } from "common/Menu.js";

export default class Sitemap extends PureComponent {
	static propTypes = {
		node: PropTypes.shape({
			type: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			alias: PropTypes.string.isRequired,
			body: PropTypes.string
		}),
		menu: PropTypes.array.isRequired
	};

	render() {
		const { node, menu } = this.props;
		return (
			<Fragment>
				<h1>{node.title}</h1>
				{node.body && <ClickableDrupalContent content={node.body} />}
				<menu className="sitemap" onClick={handleExpandCollapseTreeDom}>
					{renderMenu(menu)}
				</menu>
			</Fragment>
		);
	}
}
