import React, { useState, useRef, useEffect } from 'react';

function Tooltip({ NationalMeasures,text, country, status }) {
  const [isVisible, setIsVisible] = useState(false);
  const implTooltip = 'tooltip';
  const tooltipRef = useRef(null);

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  if (country === '') {
    return (
      <div className={`${implTooltip}`}>
        <span className={`button small ${status}`}>
          {text}
        </span>
      </div>
    );
  } else {
    return (
      <div className={`${implTooltip}`} ref={tooltipRef}>
        <button className={`info-tooltip button small ${status}`} onClick={handleToggleVisibility}>
          {text}
        </button>
        {isVisible && (
          <div className="tooltip-data">
          <button className="close-button" onClick={handleClose}>X</button>
          <div className="tooltip-data-inner" dangerouslySetInnerHTML={{ __html: NationalMeasures || 'No information available' }}></div>
          </div>
        )}
      </div>
    );
  }
}

export default Tooltip;