import axios from "axios";
import removeFbclid from "remove-fbclid";
import MatomoTracker from "@datapunt/matomo-tracker-js";

export const BACKEND_URL = "/cms/";

export const GLOBAL_BLOCK_TITLES = "footer-top,footer-mid-first,footer-mid-second,footer-mid-third,footer-bottom";

export const DEFAULT_LANGUAGE = "en";

export const FRIENDLY_CAPTCHA_KEY = "FCMMVG92O1GQRNN8";

const MATOMO_URL = "https://eftasurvint.matomo.cloud/"; // ESA-325.
const MATOMO_SRC_URL = "//cdn.matomo.cloud/eftasurvint.matomo.cloud/matomo.js"; // ESA-325.
const MATOMO_SITE = 1; // ESA-325.

export default function configureApp() {
	removeFbclid();
	configureAxios();
	registerServiceWorker();
	startPiwikTracker();
	trackPageView();
}

function configureAxios() {
	console.log("Configuring axios");
	axios.defaults.timeout = process.env.NODE_ENV === "production" ? 20000 : 10000;
	axios.defaults.baseURL = BACKEND_URL;
}

function registerServiceWorker() {
	if (!("serviceWorker" in navigator)) {
		console.warn("Service workers are not supported by this browser");
		return;
	}
	console.log("Registering service worker");
	navigator.serviceWorker
		.register("/service-worker.js", {
			scope: "/"
		})
		.then(
			() => {
				console.log("Service worker has been registered");
			},
			e => {
				console.error("Service worker registration failed", e);
			}
		);
}

let tracker;
function startPiwikTracker() {
	// Only do piwik tracking on a production build.
	if (process.env.NODE_ENV !== "production") return;

	tracker = new MatomoTracker({
		urlBase: MATOMO_URL,
		srcUrl: MATOMO_SRC_URL,
		trackerUrl: MATOMO_URL + "/matomo.php",
		siteId: MATOMO_SITE,
		linkTracking: true,
		configurations: {
			disableCookies: true
		}
	});
}

export const trackPageView = () => tracker && tracker.trackPageView();
