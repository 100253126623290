import React from "react";
import PropTypes from "prop-types";
import { renderDate } from "./utils.js";

export const renderDocument = (t, document) => (
	<a href={document.attachment.url}>
		{document.title}
		<div>
			{document.date && (
				<span>
					{t("RelatedDocuments.date")}: {renderDate(document.date, t("locale.full"))}
				</span>
			)}
			{document.description && (
				<span>
					{t("RelatedDocuments.description")}: {document.description}
				</span>
			)}
			{document.country && (
				<span>
					{t("RelatedDocuments.country")}: {document.country.name}
				</span>
			)}
			{document.number && (
				<span>
					{t("RelatedDocuments.number")}: {document.number}
				</span>
			)}
			{document.state && (
				<span>
					{t("RelatedDocuments.state")}: {document.state}
				</span>
			)}
			{document.type && (
				<span>
					{t("RelatedDocuments.type")}: {document.type}
				</span>
			)}
			{document.caseName && (
				<span>
					{t("RelatedDocuments.caseName")}: {document.caseName}
				</span>
			)}
			{document.caseNumber && (
				<span>
					{t("RelatedDocuments.caseNumber")}: {document.caseNumber}
				</span>
			)}
			{document.collegeDecision && (
				<span>
					{t("RelatedDocuments.collegeDecision")}: {document.collegeDecision}
				</span>
			)}
			{document.collegeDecisionNumber && (
				<span>
					{t("RelatedDocuments.collegeDecisionNumber")}: {document.collegeDecisionNumber}
				</span>
			)}
			{document.controlSystem && (
				<span>
					{t("RelatedDocuments.controlSystem")}: {document.controlSystem}
				</span>
			)}
			{document.market && (
				<span>
					{t("RelatedDocuments.market")}: {document.market}
				</span>
			)}
			{document.oldMarket && (
				<span>
					{t("RelatedDocuments.oldMarket")}: {document.oldMarket}
				</span>
			)}
			{document.missionId && (
				<span>
					{t("RelatedDocuments.missionId")}: {document.missionId}
				</span>
			)}
		</div>
	</a>
);

const RelatedDocuments = ({ t, relatedDocuments }) => {
	if (!relatedDocuments || !relatedDocuments.length) return null;
	return (
		<div className="RelatedDocuments">
			<h4>{t("RelatedDocuments.label")}</h4>
			<ul>
				{relatedDocuments.map((document, index) => (
					<li key={index}>{renderDocument(t, document)}</li>
				))}
			</ul>
		</div>
	);
};

export const documentPropTypes = PropTypes.shape({
	title: PropTypes.string.isRequired,
	number: PropTypes.string,
	date: PropTypes.number,
	state: PropTypes.string,
	type: PropTypes.string,
	caseNumber: PropTypes.string,
	caseName: PropTypes.string,
	attachment: PropTypes.shape({
		description: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired
	}).isRequired
});

export const relatedDocumentsPropTypes = PropTypes.arrayOf(documentPropTypes);

RelatedDocuments.propTypes = {
	t: PropTypes.func.isRequired,
	relatedDocuments: relatedDocumentsPropTypes
};

export default React.memo(RelatedDocuments);
