import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import history from "skeleton/history.js";
import { escapeHtml, serializeForm, unserializeForm, applyActiveFieldClass } from "common/utils.js";
import Paginator from "common/Paginator.js";

export default class Search extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.shape({
			type: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			alias: PropTypes.string.isRequired,
			body: PropTypes.string,
			summary: PropTypes.string,
			search: PropTypes.shape({
				page: PropTypes.number.isRequired,
				nodesPerPage: PropTypes.number.isRequired,
				nodesCount: PropTypes.number.isRequired,
				words: PropTypes.arrayOf(PropTypes.string).isRequired,
				nodes: PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						bundle: PropTypes.string.isRequired,
						alias: PropTypes.string.isRequired,
						excerpt: PropTypes.string
					})
				).isRequired
			}).isRequired
		}).isRequired,
		search: PropTypes.string
	};

	onSubmit = e => {
		e.preventDefault();
		history.push("?" + serializeForm(this.formDomNode));
	};

	enhanceDomForm = () => {
		// Populate form with content from URL.
		unserializeForm(this.formDomNode, this.props.search);

		// Toggle CSS animation related class on focus/blur.
		applyActiveFieldClass(this.formDomNode);
	};

	componentDidMount() {
		this.enhanceDomForm();
		this.formDomNode.querySelector("input").focus();
	}

	componentDidUpdate(prevProps) {
		// Switched to another search page in the site (rare possibility).
		if (prevProps.node.alias !== this.props.node.alias) {
			this.enhanceDomForm();
		}
		// Switched to a different search (maybe using browser back and forth).
		if (prevProps.search !== this.props.search) {
			this.enhanceDomForm();
		}
	}

	render() {
		const { t, node, search } = this.props;

		const renderForm = () => (
			<form method="get" onSubmit={this.onSubmit} ref={node => (this.formDomNode = node)}>
				<div className="fields">
					<div className="field-type-text search">
						<label htmlFor="field-name-q">{t("Search.search")}</label>
						<input id="field-name-q" type="text" maxLength="64" name="q" />
					</div>
				</div>
				<div className="actions">
					<input className="button small hollow" type="reset" value={t("reset")} />
					<button className="button small" type="submit">
						{t("apply")}
					</button>
				</div>
			</form>
		);

		const renderPaginator = () => (
			<Paginator
				t={t}
				search={search}
				page={node.search.page}
				nodesPerPage={node.search.nodesPerPage}
				nodesCount={node.search.nodesCount}
			/>
		);

		const renderNodes = () =>
			node.search.nodes.length > 0 && (
				<div>
					<ol className={`content`} start={(node.search.page - 1) * node.search.nodesPerPage + 1}>
						{node.search.nodes.map((result, index) => {
							// Highlighting possible word matches on title.
							// Rendering them in a span since <NavLink /> cannot
							// accept dangerouslySetInnerHTML.
							const titleWithHighlightedWords = (
								<span
									dangerouslySetInnerHTML={{
										__html: escapeHtml(result.title).replace(
											new RegExp(`\\b(${node.search.words.join("|")})\\b`, "gi"),
											"<strong>$1</strong>"
										)
									}}
								/>
							);
							return (
								<li key={index} className={`ct_${result.bundle}`}>
									{result.bundle === "document" ? (
										<a href={result.alias}>{titleWithHighlightedWords}</a>
									) : (
										<NavLink to={result.alias}>{titleWithHighlightedWords}</NavLink>
									)}
									{result.excerpt && <ClickableDrupalContent content={result.excerpt} />}
								</li>
							);
						})}
					</ol>
				</div>
			);

		return (
			<Fragment>
				<h1>{node.title}</h1>
				{node.body && <ClickableDrupalContent content={node.body} />}
				{renderForm()}
				{renderPaginator()}
				{renderNodes()}
			</Fragment>
		);
	}
}
