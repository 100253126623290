import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Block from "common/Block.js";
import AppLogo from "./AppLogo.js";
import { scrollTo } from "common/utils.js";

export default class AppFooter extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		blocks: PropTypes.object.isRequired
	};

	render() {
		const { t, blocks } = this.props;
		return (
			<footer className="AppFooter esa-decor">
				<span className="esa-decorative-line">&nbsp;</span>
				<AppLogo t={t} />

				<div className="footer-content">
					<Block name="footer-top" blocks={blocks} />
					<div className="footer-middle">
						<Block name="footer-mid-first" blocks={blocks} />
						<Block name="footer-mid-second" blocks={blocks} />
						<Block name="footer-mid-third" blocks={blocks} />
					</div>
					<Block name="footer-bottom" blocks={blocks} />
				</div>

				<div className="back-to-top">
					<a
						href="#App"
						rel="nofollow"
						onClick={e => {
							e.preventDefault();
							scrollTo({ top: 0, behavior: "smooth" });
						}}
					>
						Back to top
					</a>
				</div>
			</footer>
		);
	}
}
