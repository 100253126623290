import React from "react";
import PropTypes from "prop-types";

const SocialShare = ({ t, url }) => {
	const shareTitle = encodeURIComponent(window.document.title);
	const shareUrl = encodeURI(window.location.origin + url);
	return (
		<div className="SocialShare">
			<div className="label">{t("SocialShare.label")}</div>
			<ul>
				<li className="social-facebook">
					<a
						href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
						rel="noopener noreferrer nofollow"
						target="_blank"
					>
						{t("SocialShare.facebook")}
					</a>
				</li>
				<li className="social-linkedIn">
					<a
						rel="noopener noreferrer nofollow"
						href={`https://www.linkedin.com/shareArticle/?mini=true&url=${shareUrl}&title=${shareTitle}`}
						target="_blank"
					>
						{t("SocialShare.linkedin")}
					</a>
				</li>
				<li className="social-twitter">
					<a
						rel="noopener noreferrer nofollow"
						href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}`}
						target="_blank"
					>
						{t("SocialShare.twitter")}
					</a>
				</li>
			</ul>
		</div>
	);
};

SocialShare.propTypes = {
	t: PropTypes.func.isRequired,
	url: PropTypes.string.isRequired
};

export default React.memo(SocialShare);
