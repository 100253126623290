import { useEffect, useRef } from "react";
import { WidgetInstance } from "friendly-challenge";
// @todo: Change key path to something private.
import { FRIENDLY_CAPTCHA_KEY } from "config.js";

const FriendlyCaptcha = ({ handleCaptchaValidation }) => {
	const container = useRef();
	const widget = useRef();

	const doneCallback = solution => {
		// Uncomment for testing.
		// console.log(solution);
		if (solution) {
			handleCaptchaValidation(true);
			return;
		}
		handleCaptchaValidation(false);
	};

	const errorCallback = err => {
		// Uncomment for testing.
		// console.log(err);
		handleCaptchaValidation(false);
	};

	useEffect(() => {
		if (!widget.current && container.current) {
			widget.current = new WidgetInstance(container.current, {
				startMode: "auto",
				doneCallback: doneCallback,
				errorCallback: errorCallback
			});
		}

		return () => {
			if (widget.current !== undefined) widget.current.reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [container]);

	return <div ref={container} className="frc-captcha" data-sitekey={FRIENDLY_CAPTCHA_KEY} />;
};

export default FriendlyCaptcha;
