import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Attachments, { attachmentsPropTypes } from "common/Attachments.js";
import RelatedContent, { relatedContentPropTypes } from "common/RelatedContent.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import RelatedDocuments, { relatedDocumentsPropTypes } from "common/RelatedDocuments.js";
import RelatedLinks, { relatedLinksPropTypes } from "common/RelatedLinks.js";
import NodeImage from "common/NodeImage.js";
import SocialShare from "common/SocialShare.js";
import { renderUrl } from "common/utils.js";
import NodeMeta from "common/NodeMeta.js";
import Term, { termPropTypes } from "common/Term.js";
import Wrapper from "common/Wrapper.js";
import { imagePropTypes } from "common/Image.js";

const Event = ({ t, node }) => (
	<Fragment>
		<NodeMeta t={t} node={node} />
		<h1>{node.title}</h1>
		<NodeImage image={node.image} />
		<ClickableDrupalContent content={node.body} />
		<Attachments t={t} attachments={node.attachments} />
		<RelatedDocuments t={t} relatedDocuments={node.relatedDocuments} />
		<RelatedLinks t={t} relatedLinks={node.relatedLinks} />
		<Wrapper className="NodeMetadataWrapper">
			{node.eventType && (
				<div className="metadata">
					<div className="label">{t("Term.event_types")}</div>
					<div>
						<Term term={node.eventType} />
					</div>
				</div>
			)}
		</Wrapper>
		<SocialShare t={t} url={renderUrl(node.alias, node.language)} />
		<RelatedContent t={t} relatedContent={node.relatedContent} />
	</Fragment>
);

const eventPropTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	body: PropTypes.string.isRequired,
	image: imagePropTypes,
	attachments: attachmentsPropTypes,
	relatedContent: relatedContentPropTypes,
	relatedDocuments: relatedDocumentsPropTypes,
	relatedLinks: relatedLinksPropTypes,
	eventType: termPropTypes.isRequired,
	startDate: PropTypes.number.isRequired,
	endDate: PropTypes.number.isRequired,
	location: PropTypes.string
};

Event.propTypes = {
	t: PropTypes.func.isRequired,
	node: PropTypes.shape(eventPropTypes)
};

export default React.memo(Event);
