import { BACKEND_URL, DEFAULT_LANGUAGE } from "config.js";

/**
 * Returns a human readable representation of the specified bytes file size.
 * Only supports byte, KB, MB and GB units since this is a webapp. To be 100%
 * correct we'd need to output byte, KiB, MiB and GiB, but the average web user
 * is not yet familiar with those units.
 */
export function getHumanReadableFileSize(bytes) {
	if (typeof bytes !== "number" || isNaN(bytes)) return "";
	if (bytes < 1024) return `${bytes} bytes`;
	if (bytes < 1024 * 1024) return `${parseFloat((bytes / 1024).toFixed(1))} KB`;
	if (bytes < 1024 * 1024 * 1024) return `${parseFloat((bytes / 1024 / 1024).toFixed(1))} MB`;
	return `${parseFloat((bytes / 1024 / 1024 / 1024).toFixed(1))} GB`;
}

/**
 * Ease-in-out parametric as shown in https://stackoverflow.com/a/25730573/72478
 * For specified value between 0 and 1 the result will be between 0 and 1.
 */
export const easeInOutParametric = value => {
	const squareValue = value * value;
	return squareValue / (2 * (squareValue - value) + 1);
};

/**
 * Will toggle animate the height of an element.
 * Animating an element's height from 0 to auto is not fully possible via CSS.
 *
 * Demo: https://codesandbox.io/s/css-height-auto-animation-8jgbz
 */
export const toggleHeight = (element, duration = 300, easing = easeInOutParametric) => {
	const now = Date.now();

	// An existing animation is in place.
	// Change params and let it finish the job.
	if (element.dataset.start) {
		const start = Number(element.dataset.start);
		const end = Number(element.dataset.end);
		const direction = Number(element.dataset.direction);
		element.dataset.end = now - start + now;
		element.dataset.start = now - (end - now);
		element.dataset.direction = -direction;
		return;
	}

	// Fresh animation.
	element.dataset.end = now + duration;
	element.dataset.start = now;
	element.dataset.direction = element.clientHeight === 0 ? +1 : -1;
	const calculateAndApplyNextFrame = () => {
		const now = Date.now();
		const start = Number(element.dataset.start);
		const end = Number(element.dataset.end);
		const direction = Number(element.dataset.direction);
		// Animation is done.
		if (now > end) {
			if (direction === 1) {
				expandToFinalHeight(element);
			} else {
				collapseToFinalHeight(element);
			}
			delete element.dataset.end;
			delete element.dataset.start;
			delete element.dataset.direction;
			return;
		}
		const frame =
			direction === 1 ? easing((now - start) / (end - start)) : 1 - easing((now - start) / (end - start));
		element.style.display = "block";
		element.style.height = element.scrollHeight * frame + "px";
		element.style.overflow = "hidden";
		window.requestAnimationFrame(calculateAndApplyNextFrame);
	};
	window.requestAnimationFrame(calculateAndApplyNextFrame);
};

/**
 * See #toggleHeight().
 */
export const expandToFinalHeight = element => {
	element.style.display = "block";
	element.style.height = "auto";
	element.style.overflow = "visible";
};

/**
 * See #toggleHeight().
 */
export const collapseToFinalHeight = element => {
	element.style.display = "none"; // Display none so links/buttons within are not focusable via tab.
	element.style.height = "0px";
	element.style.overflow = "hidden";
};

/**
 * Returns the date and time of the specified timestamp.
 *
 * @param {number} timestamp - Unix timestamp in seconds.
 * @param {string} locale - The locale (in IETF BCP 47 language tag format).
 */
export const renderDate = (timestamp, locale) =>
	new Date(timestamp * 1000)
		.toLocaleString(locale, {
			day: "2-digit",
			month: "short",
			year: "numeric"
		})
		.toUpperCase();

/**
 * Returns the date of the specified timestamp in ISO 8601 format.
 *
 * @param {number} timestamp - Unix timestamp in seconds.
 */
export const renderDateIso = timestamp => new Date(timestamp * 1000).toISOString().substr(0, 10);

/**
 * Returns the URL of a node taking into account alias and language.
 */
export function renderUrl(alias, language) {
	if (!language || language === DEFAULT_LANGUAGE) return alias;
	return `${alias}:${language}`;
}

/**
 * Returns the URL parameters representation of an HTML form.
 * Currently only supports what is necessary (e.g no multiple values for same name).
 * e.g:
 * - foo=bar
 * - foo=bar&example=123
 */
export const serializeForm = (formDomNode, trim = true) => {
	const result = [];
	formDomNode.querySelectorAll("[name]").forEach(field => {
		const value = trim ? field.value.trim() : field.value;
		if (!value) return; // Continue.
		result.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(value));
	});
	return result.join("&");
};

/**
 * Sets the field values of a form based on a URL parameters string.
 */
export const unserializeForm = (formDomNode, search) => {
	const urlParams = new URLSearchParams(search);
	formDomNode.querySelectorAll("[name]").forEach(field => {
		field.value = urlParams.get(field.name);
	});
};

/**
 * Returns the FormData object for an HTML form.
 */
export const serializeFormToFormData = (formDomNode, trim = true) => {
	const formData = new FormData();
	formDomNode.querySelectorAll("[name]").forEach(field => {
		if (field.type === "file") {
			Array.from(field.files).forEach(file => {
				formData.append(field.name + "[]", file, file.name);
			});
		} else {
			const value = trim ? field.value.trim() : field.value;
			if (!value) return; // Continue.
			formData.set(field.name, value);
		}
	});
	return formData;
};

/**
 * Decodes query parameters from the specified URL query string (for the form
 * "foo=bar&test=123").
 *
 */
export const decodeQuery = query => {
	if (typeof query !== "string") return {};
	return query.split("&").reduce((result, param) => {
		const tokens = param.split("=");
		if (tokens.length !== 2) return result; // continue
		try {
			result[decodeURIComponent(tokens[0]).trim()] = decodeURIComponent(tokens[1] || "").trim();
		} catch (error) {
			// Ignore URIError and skip parameter.
		}
		return result;
	}, {});
};

/**
 * Encodes a query parameter object into a URL query string (into the form
 * "foo=bar&test=123").)
 */
export const encodeQuery = params =>
	Object.entries(params)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join("&");

/**
 * Escapes HTML.
 *
 * https://stackoverflow.com/a/6234804/72478
 */
export const escapeHtml = html =>
	html
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#039;");

/**
 * Toggles a CSS animation related class on focus/blur on form fields.
 */
export const applyActiveFieldClass = formDomNode =>
	formDomNode.querySelectorAll("[name]").forEach(field => {
		// Toggle .active based on existence on field value.
		field.closest("div").classList.toggle("active", field.value);

		// Stop further processing if applyActiveFieldClass has already been applied on this field.
		if (field.dataset.applyActiveFieldClass) return; // continue

		// Add .active on focus.
		field.addEventListener("focus", () => {
			field.closest("div").classList.add("active");
		});

		// Remove .active on blur and if field doesn't have content.
		field.addEventListener("blur", () => {
			if (field.value) return;
			field.closest("div").classList.remove("active");
		});

		// Mark that applyActiveFieldClass has been applied to this field.
		// This is used to prevent multiple invocations of applyActiveFieldClass
		// to add multiple times the same event handler on fields.
		// An alternative would be to bind those handlers in a higher level
		// and bind them by name.
		field.dataset.applyActiveFieldClass = "1";
	});

/**
 * Allows for custom input[type=file] styling using the technique described in:
 * https://css-tricks.com/snippets/css/custom-file-input-styling-webkitblink/
 *
 */
export const applyInputFileEnhancements = (formDomNode, t) =>
	formDomNode.querySelectorAll('[type="file"]').forEach(field => {
		// Get input's associated label text.
		// We know that the label and input are wrapped on a common parent, so the following works:
		const labelText = field.parentElement.querySelector("label").innerText;

		// Wrap input[type=file] in a label.
		const label = document.createElement("label");
		field.parentNode.insertBefore(label, field);
		label.appendChild(field);

		// Append a span in the label and make it look like a button.
		// We'll use this to communicate field state to the user (e.g 4 files)
		const span = document.createElement("span");
		span.className = "button hollow small";
		span.innerText = labelText;
		label.appendChild(span);

		// Adapt the span text when the field files change.
		field.addEventListener("change", () => {
			if (field.files.length === 0) {
				span.innerText = labelText;
			} else if (field.files.length === 1) {
				span.innerText = field.files[0].name;
			} else {
				span.innerText = t("files.many", field.files.length);
			}
		});

		// Reset the span text when the parent form is reset.
		field.form.addEventListener("reset", () => {
			span.innerText = labelText;
		});
	});

export function isExternalLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^(?:[a-z+]+:)?\/\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+/) !== null;
}

// https://stackoverflow.com/a/3561711/72478
const escapeForRegExp = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
const cmsLinkRegExp = new RegExp("^" + escapeForRegExp(BACKEND_URL));
export function isCmsLink(href) {
	if (!href || typeof href !== "string") return false;
	return cmsLinkRegExp.test(href);
}

const staticAssetLinkRegExp = new RegExp(/^[^?]+\.[^/?]{2,5}(\?.*)?$/);
export function isStaticAssetLink(href) {
	// ^/.+\.[^/]{2,5}$
	if (!href || typeof href !== "string") return false;
	return staticAssetLinkRegExp.test(href);
}

export function isAnchorLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^#/) !== null;
}

export function isMailLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^mailto:/) !== null;
}

export function isTelLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^tel:/) !== null;
}

/**
 * A wrapper around window.scrollTo to support old browsers.
 */
export const scrollTo = options => {
	try {
		window.scrollTo(options);
	} catch (error) {
		window.scrollTo(options.left || 0, options.top || 0);
	}
};
