import { PureComponent } from "react";
import PropTypes from "prop-types";

export default class DocumentMeta extends PureComponent {
	static propTypes = {
		description: PropTypes.string
	};

	original = {};

	componentWillUnmount() {
		Object.entries(this.original).forEach(([key, { selector, attribute, value }]) => {
			this.set(selector, attribute, value);
		});
	}

	componentDidUpdate() {
		// Remove any og: related metadata which may exist on the HTML which
		// were generated by ssr.php. We do not need those nor do we need to
		// keep them up to date since social bots (facebook, twitter) only care
		// about the server generated HTML.
		// Removing them here means that we don't need to keep them up to date
		// while the user navigates the app using react.
		const ogMetaElements = window.document.querySelectorAll('html > head > meta[property^="og:"]');
		Array.prototype.forEach.call(ogMetaElements, ogMetaElement =>
			ogMetaElement.parentNode.removeChild(ogMetaElement)
		);
		// Also remove the canonical link for the reasons stated above.
		const canonical = window.document.querySelector('html > head > link[rel="canonical"]');
		if (canonical) {
			canonical.parentNode.removeChild(canonical);
		}
	}

	getDescription() {
		return this.get("html > head > meta[name='description']", "content");
	}

	setDescription(value) {
		this.set("html > head > meta[name='description']", "content", value);
	}

	set(selector, attribute, value) {
		if (value === undefined) return;
		const node = window.document.querySelector(selector);
		if (!node) return;
		if (this.original[selector + attribute] === undefined) {
			this.original[selector + attribute] = {
				selector: selector,
				attribute: attribute,
				value: this.get(selector, attribute)
			};
		}
		node.setAttribute(attribute, value == null ? "" : value);
	}

	get(selector, attribute) {
		const node = window.document.querySelector(selector);
		if (!node) return undefined;
		return node.getAttribute(attribute);
	}

	render() {
		const { description } = this.props;
		if (description !== undefined) {
			this.setDescription(description);
		}
		return null;
	}
}
