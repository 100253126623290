import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Image, { imagePropTypes } from "./Image.js";
import { isExternalLink } from "./utils.js";

export const itemsPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		image: imagePropTypes.isRequired,
		title: PropTypes.string.isRequired,
		links: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string.isRequired,
				title: PropTypes.string.isRequired
			})
		)
	})
);

export default class Carousel extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		items: itemsPropTypes
	};

	state = {
		visibleItem: 0
	};

	componentDidMount() {
		this.alignImages();
		window.addEventListener("resize", this.alignImages);
	}

	componentDidUpdate() {
		this.alignImages();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.alignImages);
	}

	displayItem = index => {
		const boundedIndex = Math.max(0, Math.min(this.props.items.length - 1, index));
		this.setState({ visibleItem: boundedIndex });
		this.updateWindowScroll(boundedIndex);
	};

	updateWindowScroll = index =>
		(this.carouselDomNode.querySelector(".items-wrapper").style.transform = `translateX(${
			-index * this.carouselDomNode.offsetWidth
		}px)`);

	/**
	 * Makes sure all images (which have a free aspect ratio from the CMS) are
	 * zoomed and aligned properly in order to not leave any black margins at
	 * top/bottom or left/right.
	 *
	 * Note: There is a bug here with the image.offsetWidth and image.offsetHeight
	 * because those images are rendered with loading="lazy". This is not a
	 * problem though since the content managers will upload images with the
	 * same aspect.
	 */
	alignImages = () => {
		if (!this.carouselDomNode) return;
		const carouselRatio = this.carouselDomNode.offsetWidth / this.carouselDomNode.offsetHeight;
		this.carouselDomNode.querySelectorAll(".item img").forEach(image => {
			const imageRatio = image.offsetWidth / image.offsetHeight;
			if (imageRatio <= carouselRatio) {
				image.style.width = "100%";
				image.style.height = "auto";
			} else {
				image.style.height = "100%";
				image.style.width = "auto";
			}
		});
		this.updateWindowScroll(this.state.visibleItem);
	};

	render() {
		const { t, items } = this.props;
		if (!items || !items.length) return null;

		const { visibleItem } = this.state;

		return (
			<div className="Carousel" ref={node => (this.carouselDomNode = node)}>
				<div className="items-wrapper">
					{items.map((item, index) => (
						<div key={index} className="item">
							<div className="image-wrapper">
								<Image image={item.image} />
							</div>
							<div className="text">
								<h2>{item.title}</h2>
								{item.links &&
									item.links.map((link, index) =>
										isExternalLink(link.url) ? (
											<a
												key={index}
												href={link.url}
												className="button"
												rel="external noopener noreferrer"
												target="_blank"
											>
												{link.title}
											</a>
										) : (
											<NavLink key={index} to={link.url} className="button">
												{link.title}
											</NavLink>
										)
									)}
							</div>
						</div>
					))}
				</div>

				{items && items.length > 1 && (
					<nav>
						<button
							onClick={() => this.displayItem(visibleItem - 1)}
							className={`previous${visibleItem === 0 ? " disabled" : ""}`}
							disabled={visibleItem === 0}
						>
							{t("previous")}
						</button>
						<ul>
							{items.map((item, index) => (
								<li key={index}>
									<button
										onClick={() => this.displayItem(index)}
										className={visibleItem === index ? "active" : ""}
									>
										{index + 1}
									</button>
								</li>
							))}
						</ul>
						<button
							onClick={() => this.displayItem(visibleItem + 1)}
							className={`next${visibleItem === items.length - 1 ? " disabled" : ""}`}
							disabled={visibleItem === items.length - 1}
						>
							{t("next")}
						</button>
					</nav>
				)}
			</div>
		);
	}
}
