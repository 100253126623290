import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Languages, { shouldRender as shouldRenderLanguages } from "./Languages.js";
import NodeDate from "./NodeDate.js";
import DateRange from "./DateRange.js";
import Term from "./Term.js";
import Wrapper from "./Wrapper.js";

export default class NodeMeta extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.object.isRequired
	};

	render() {
		const { t, node } = this.props;
		return (
			<Wrapper className="NodeMeta">
				{shouldRenderLanguages(node.languages) && (
					<Languages languages={node.languages} language={node.language} alias={node.alias} />
				)}
				{node.date && <NodeDate t={t} date={node.date} />}
				{node.startDate && <DateRange t={t} startDate={node.startDate} endDate={node.endDate} />}
				{node.theme && <Term term={node.theme} />}
				{node.location && <span className="location">{node.location}</span>}
			</Wrapper>
		);
	}
}
