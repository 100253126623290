import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "./ClickableDrupalContent.js";

export default class Block extends PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		body: PropTypes.string,
		blocks: PropTypes.object
	};

	render() {
		const { name, blocks } = this.props;
		let { body } = this.props;

		// Allow client code to pass blocks object and have name pick the correct body.
		if (blocks && blocks[name]) body = blocks[name];

		// Do not render component if there is no content found.
		if (!body) return null;

		return (
			<div className={`Block ${name}`}>
				<ClickableDrupalContent content={body} />
			</div>
		);
	}
}
