import React from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "./ClickableDrupalContent.js";
import Image, { imagePropTypes } from "./Image.js";

const ContactPersons = ({ t, contactPersons }) => {
	if (!contactPersons || !contactPersons.length) return null;
	return (
		<ul className="ContactPersons">
			{contactPersons.map((contactPerson, index) => (
				<li key={index} className="esa-decor">
					<span className="esa-decorative-line">&nbsp;</span>
					<div className="image-wrapper">{contactPerson.image && <Image image={contactPerson.image} />}</div>
					<div className="content-wrapper">
						<h4>{contactPerson.name}</h4>
						{contactPerson.description && <ClickableDrupalContent content={contactPerson.description} />}
						<div className="email">
							{t("ContactPersons.email")}:{" "}
							<a href={`mailto:${contactPerson.email}`}>{contactPerson.email}</a>
						</div>
						{contactPerson.phone && (
							<div className="phone">
								{t("ContactPersons.phone")}:{" "}
								<a href={`tel:${contactPerson.phone}`}>{contactPerson.phone}</a>
							</div>
						)}
						{contactPerson.mobile && (
							<div className="mobile">
								{t("ContactPersons.mobile")}:{" "}
								<a href={`tel:${contactPerson.mobile}`}>{contactPerson.mobile}</a>
							</div>
						)}
					</div>
				</li>
			))}
		</ul>
	);
};

export const contactPersonsPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		name: PropTypes.string.isRequired,
		description: PropTypes.string,
		email: PropTypes.string.isRequired,
		phone: PropTypes.string,
		mobile: PropTypes.string,
		image: imagePropTypes
	})
);

ContactPersons.propTypes = {
	t: PropTypes.func.isRequired,
	contactPersons: contactPersonsPropTypes
};

export default React.memo(ContactPersons);
