import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Attachments, { attachmentsPropTypes } from "common/Attachments.js";
import RelatedContent, { relatedContentPropTypes } from "common/RelatedContent.js";
import RelatedDocuments, { relatedDocumentsPropTypes } from "common/RelatedDocuments.js";
import RelatedLinks, { relatedLinksPropTypes } from "common/RelatedLinks.js";
import NodeMeta from "common/NodeMeta.js";
import { renderUrl } from "common/utils.js";
import SocialShare from "common/SocialShare.js";
import Wrapper from "common/Wrapper.js";
import { termPropTypes } from "common/Term.js";

export default class Decision extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.shape({
			type: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			alias: PropTypes.string.isRequired,
			language: PropTypes.string.isRequired,
			languages: PropTypes.arrayOf(PropTypes.string).isRequired,
			body: PropTypes.string.isRequired,
			attachments: attachmentsPropTypes,
			relatedContent: relatedContentPropTypes,
			relatedDocuments: relatedDocumentsPropTypes,
			relatedLinks: relatedLinksPropTypes,
			number: PropTypes.string.isRequired,
			theme: termPropTypes.isRequired,
			date: PropTypes.number.isRequired,
			country: PropTypes.shape({
				code: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired
			}).isRequired,
			decisionType: PropTypes.string,
			decisionOjReference: PropTypes.string,
			decisionEftaProcedure: PropTypes.string,
			decisionEeaSupplement: PropTypes.string
		})
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<NodeMeta t={t} node={node} />
				<h1>{node.title}</h1>
				<ClickableDrupalContent content={node.body} />
				<Attachments t={t} attachments={node.attachments} />
				<RelatedDocuments t={t} relatedDocuments={node.relatedDocuments} />
				<RelatedLinks t={t} relatedLinks={node.relatedLinks} />
				<Wrapper className="NodeMetadataWrapper">
					<div className="metadata">
						<div className="label">{t("Decision.number")}</div>
						<div>{node.number}</div>
					</div>
					<div className="metadata">
						<div className="label">{t("Decision.country")}</div>
						<div>{node.country.name}</div>
					</div>
					{node.decisionType && (
						<div className="metadata">
							<div className="label">{t("Decision.decisionType")}</div>
							<div>{node.decisionType}</div>
						</div>
					)}
					{node.decisionOjReference && (
						<div className="metadata">
							<div className="label">{t("Decision.decisionOjReference")}</div>
							<div>
								<ClickableDrupalContent content={node.decisionOjReference} />
							</div>
						</div>
					)}
					{node.decisionEftaProcedure && (
						<div className="metadata">
							<div className="label">{t("Decision.decisionEftaProcedure")}</div>
							<div>
								<ClickableDrupalContent content={node.decisionEftaProcedure} />
							</div>
						</div>
					)}
					{node.decisionEeaSupplement && (
						<div className="metadata">
							<div className="label">{t("Decision.decisionEeaSupplement")}</div>
							<div>
								<ClickableDrupalContent content={node.decisionEeaSupplement} />
							</div>
						</div>
					)}
				</Wrapper>
				<SocialShare t={t} url={renderUrl(node.alias, node.language)} />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}
