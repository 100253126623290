import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import NodeDate from "./NodeDate.js";
import NodeImage from "./NodeImage.js";
import { imagePropTypes } from "./Image.js";

export const relatedContentPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		type: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		alias: PropTypes.string.isRequired,
		image: imagePropTypes,
		date: PropTypes.number
	})
);

export default class RelatedContent extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		relatedContent: relatedContentPropTypes,
		maxSlidesToShow: PropTypes.number
	};

	static defaultProps = {
		maxSlidesToShow: 4
	};

	getSlidesToShow = size => {
		const { maxSlidesToShow } = this.props;
		if (size === "large" && maxSlidesToShow === 4) return 3;
		if (size === "large" && maxSlidesToShow === 3) return 3;
		if (size === "med" && maxSlidesToShow === 4) return 2;
		if (size === "med" && maxSlidesToShow === 3) return 2;
		if (size === "small" && maxSlidesToShow === 4) return 1;
		if (size === "small" && maxSlidesToShow === 3) return 1;
		return 1;
	};

	/**
	 * Due to mockup requirements this component needs to look like it's detached
	 * from its parent. Instead of implementing it with React Portals a CSS
	 * solution was developed. For that to work perfectly though an inline CSS
	 * property needs to be set on the <aside>. We do that whenever this component
	 * updates.
	 * See ESA-105 for more details.
	 */
	fixAsideHeight = () => {
		// We know that there is only 1 <aside> on the site. This also totally
		// bypasses react.
		const asideDomNode = document.querySelector("aside");
		if (!asideDomNode) return;

		if (!this.domNode) {
			asideDomNode.style.paddingBottom = ``;
		} else {
			asideDomNode.style.paddingBottom = `${this.domNode.clientHeight}px`;
		}
	};

	componentWillUnmount() {
		this.domNode = null;
		this.fixAsideHeight();
	}

	componentDidUpdate() {
		this.fixAsideHeight();
	}

	render() {
		const { t, relatedContent, maxSlidesToShow } = this.props;
		if (!relatedContent || !relatedContent.length) return null;

		const sliderSettings = {
			arrows: false,
			// to prevent https://github.com/kenwheeler/slick/issues/940
			// also: https://github.com/akiran/react-slick/issues/1171
			infinite: relatedContent.length > maxSlidesToShow,
			speed: 500,
			slidesToShow: maxSlidesToShow,
			onReInit: this.fixAsideHeight,
			slidesToScroll: 1,
			responsive: [
				{ breakpoint: 1366, settings: { slidesToShow: this.getSlidesToShow("large") } },
				{ breakpoint: 1024, settings: { slidesToShow: this.getSlidesToShow("med") } },
				{ breakpoint: 640, settings: { slidesToShow: this.getSlidesToShow("small") } }
			]
		};
		return (
			<div className="RelatedContent" ref={node => (this.domNode = node)}>
				<h2>{t("RelatedContent")}</h2>
				<div>
					{relatedContent.length > maxSlidesToShow && (
						<div className="slick-navigation">
							<button
								className="previous"
								onClick={() => {
									this.sliderDomNode.slickPrev();
								}}
							>
								{t("previous")}
							</button>
							<button
								className="next"
								onClick={() => {
									this.sliderDomNode.slickNext();
								}}
							>
								{t("next")}
							</button>
						</div>
					)}
					<Slider ref={node => (this.sliderDomNode = node)} {...sliderSettings}>
						{relatedContent.map((node, index) => (
							<NavLink to={node.alias} key={index} className={`ct_${node.type}`}>
								{node.image && <NodeImage image={node.image} />}
								{!node.image && <div className="no-image"></div>}
								<div className="esa-decor">
									<span className="esa-decorative-line">&nbsp;</span>
									<p>{node.title}</p>
									<NodeDate t={t} date={node.date} />
								</div>
							</NavLink>
						))}
					</Slider>
				</div>
			</div>
		);
	}
}
