import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Attachments, { attachmentsPropTypes } from "common/Attachments.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import RelatedDocuments, { relatedDocumentsPropTypes } from "common/RelatedDocuments.js";
import { renderUrl } from "common/utils.js";
import SocialShare from "common/SocialShare.js";
import NodeMeta from "common/NodeMeta.js";
import Term, { termPropTypes } from "common/Term.js";
import Wrapper from "common/Wrapper.js";

const FoodSafetyMission = ({ t, node }) => (
	<Fragment>
		<NodeMeta t={t} node={node} />
		<h1>{node.title}</h1>
		{node.body && <ClickableDrupalContent content={node.body} />}
		<Attachments t={t} attachments={node.attachments} />
		<RelatedDocuments t={t} relatedDocuments={node.relatedDocuments} />
		<Wrapper className="NodeMetadataWrapper">
			<div className="metadata">
				<div className="label">{t("FoodSafetyMission.country")}</div>
				<div>{node.country.name}</div>
			</div>
			<div className="metadata">
				<div className="label">{t("FoodSafetyMission.year")}</div>
				<div>{node.year}</div>
			</div>
			<div className="metadata">
				<div className="label">{t("FoodSafetyMission.category")}</div>
				<div>
					<Term term={node.foodSafetyCategory} />
				</div>
			</div>
		</Wrapper>
		<SocialShare t={t} url={renderUrl(node.alias, node.language)} />
	</Fragment>
);

const foodSafetyMissionPropTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	body: PropTypes.string,
	attachments: attachmentsPropTypes,
	relatedDocuments: relatedDocumentsPropTypes,
	year: PropTypes.number.isRequired,
	theme: termPropTypes.isRequired,
	country: PropTypes.shape({
		code: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	}).isRequired,
	foodSafetyCategory: termPropTypes.isRequired
};

FoodSafetyMission.propTypes = {
	t: PropTypes.func.isRequired,
	node: PropTypes.shape(foodSafetyMissionPropTypes)
};

export default React.memo(FoodSafetyMission);
