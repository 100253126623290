import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Attachments, { attachmentsPropTypes } from "common/Attachments.js";
import RelatedContent, { relatedContentPropTypes } from "common/RelatedContent.js";
import RelatedDocuments, { relatedDocumentsPropTypes } from "common/RelatedDocuments.js";
import RelatedLinks, { relatedLinksPropTypes } from "common/RelatedLinks.js";
import NodeMeta from "common/NodeMeta.js";
import { renderUrl } from "common/utils.js";
import SocialShare from "common/SocialShare.js";
import Wrapper from "common/Wrapper.js";
import { termPropTypes } from "common/Term.js";

export default class GberInformationSheet extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.shape({
			type: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			alias: PropTypes.string.isRequired,
			language: PropTypes.string.isRequired,
			languages: PropTypes.arrayOf(PropTypes.string).isRequired,
			body: PropTypes.string.isRequired,
			attachments: attachmentsPropTypes,
			relatedContent: relatedContentPropTypes,
			relatedDocuments: relatedDocumentsPropTypes,
			relatedLinks: relatedLinksPropTypes,
			theme: termPropTypes,
			country: PropTypes.shape({
				code: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired
			}).isRequired,
			year: PropTypes.number.isRequired,
			number: PropTypes.string.isRequired,
			gberAidReference: PropTypes.string.isRequired
		})
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<NodeMeta t={t} node={node} />
				<h1>{node.title}</h1>
				<ClickableDrupalContent content={node.body} />
				<Attachments t={t} attachments={node.attachments} />
				<RelatedDocuments t={t} relatedDocuments={node.relatedDocuments} />
				<RelatedLinks t={t} relatedLinks={node.relatedLinks} />
				<Wrapper className="NodeMetadataWrapper">
					<div className="metadata">
						<div className="label">{t("GberInformationSheet.country")}</div>
						<div>{node.country.name}</div>
					</div>
					<div className="metadata">
						<div className="label">{t("GberInformationSheet.year")}</div>
						<div>{node.year}</div>
					</div>
					<div className="metadata">
						<div className="label">{t("GberInformationSheet.number")}</div>
						<div>{node.number}</div>
					</div>
					<div className="metadata">
						<div className="label">{t("GberInformationSheet.gberAidReference")}</div>
						<div>{node.gberAidReference}</div>
					</div>
				</Wrapper>
				<SocialShare t={t} url={renderUrl(node.alias, node.language)} />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}
