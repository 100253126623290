import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import MenuLink from "./MenuLink.js";
import { renderMenu, handleExpandCollapseTreeDom } from "./Menu.js";

export default class SecondLevelMenu extends PureComponent {
	static propTypes = {
		menu: PropTypes.array.isRequired,
		node: PropTypes.object
	};

	render() {
		const { menu, node } = this.props;

		// Do not render component if there is no menu at all.
		if (!menu || !menu.length) return null;

		const currentUrl = (node && node.alias) || "";

		// Find the parent from the first level based on the current URL.
		const firstLevelParent = menu.find(
			item =>
				currentUrl === item.url ||
				(currentUrl.startsWith(item.url) && currentUrl.substr(item.url.length).startsWith("/"))
		);

		// Do not render component if a selected parent was not found or if
		// parent has no children.
		if (!firstLevelParent || !firstLevelParent.children || !firstLevelParent.children.length) return null;

		return (
			<menu className="SecondLevelMenu" onClick={handleExpandCollapseTreeDom}>
				<h3>
					<MenuLink menuItem={firstLevelParent} />
				</h3>
				{renderMenu(firstLevelParent.children, currentUrl)}
			</menu>
		);
	}
}
